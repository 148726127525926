import { ButtonHTMLAttributes, ReactElement } from 'react'
import ActionButton from './Form/ActionButton'

interface PrimaryActionButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  defaultText: string
  isActing: boolean
  actingText: string
  isSuccess: boolean
  successText: string
  className?: string
  onCancel: () => void
}

export function PrimaryActionButton({
  disabled,
  ...rest
}: PrimaryActionButtonProps): ReactElement {
  return (
    <ActionButton
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      disabled={disabled}
      {...rest}
    />
  )
}
