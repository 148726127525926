import { RegistrationLink } from './Link'

interface Props {
  text: string
  external?: boolean
  link: string
  className?: string
  linkText: string
  analyticsEventName: string
}

export function TextAndLink({
  text,
  external = false,
  link,
  className = '',
  linkText,
  analyticsEventName,
}: Props) {
  return (
    <div className={className}>
      {text}
      <RegistrationLink
        external={external}
        to={link}
        analyticsEventName={analyticsEventName}
      >
        {linkText}
      </RegistrationLink>
    </div>
  )
}
