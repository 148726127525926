import { RegistrationApiErrorResponse } from 'models/registration-api-error-response'
/**
 * Safely fetch the value of an environment variable for a given key. Always return a string,
 * even if the environment variable is not found.
 * @param key
 * @returns the value of an environment variable for a given key or empty string if not found
 */
export const getEnvValue = (key: string, fallback = ''): string =>
  process.env[`${key}`] ?? fallback

export const navigateToMissionLaneHome = () => {
  window.location.href = getEnvValue('REACT_APP_MONO_URL')
}

/**
 * Build out a request object for use as a fetch param
 * @param method
 * @param body
 * @returns RequestInit object
 */
export const buildRequest = (
  method: 'POST' | 'GET',
  registrationJwt: string,
  role = 'system',
  body?: string
) => {
  const request: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      role,
    },
    body,
    credentials: 'include',
  }
  /* If a registration token exists, add it as a header */
  if (registrationJwt)
    request.headers = {
      ...request.headers,
      ...{ Authorization: `Bearer ${registrationJwt}` },
    }
  return request
}

/**
 * True if the response code is an error, else false
 * @param response
 * @returns boolean
 */
export const isAuthError = (response: Response) => {
  return response.status === 401 || response.status === 403
}

export const isTimeoutError = async (response: Response) => {
  const errorResponse: RegistrationApiErrorResponse = await response.json()
  return errorResponse.errorType === 'TIMEOUT'
}

/**
 * @param error
 * @returns a stringified error message regardless of the type of error
 */
export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message
  return String(error)
}
