import React, { ButtonHTMLAttributes } from 'react'
// import cx from 'classnames'

export type ButtonVariant = 'primary' | 'secondary' | 'link' | 'danger'
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** The content of the button */
  children: React.ReactNode
  /** Styles the button as one of the preset variants, providing different emphasis and other visual hints to the user. */
  variant?: ButtonVariant
  /** Stops the button from receiving click events, and shows the user that the action is not currently available. */
  disabled?: boolean
  /** Sets width to 100% on the button. If false, button will be sized to fit the text it contains. Mobile breakpoints can also be set for conveninence. */
  fullWidth?: boolean | 'sm' | 'md' | 'lg'
  /** Shrinks the size of the button by reducing padding */
  small?: boolean
  /** Constrast button when is inside a dark background, false by default */
  darkMode?: boolean
}

/**
 *  Buttons allow users to take actions, and make choices, with a single tap. Supports all standard properties of `HTMLButtonElement`
 */
const Button = ({
  className = '',
  variant = 'primary',
  fullWidth = false,
  small = false,
  disabled = false,
  darkMode = false,
  onClick,
  ...buttonProps
}: ButtonProps) => {
  // TODO: Uncomment and define classNames once Tailwind class order issue is mature
  // const classNames = cx(className, {
  //   small,
  //   dark: darkMode,
  //   'w-100': fullWidth === true || fullWidth === 'sm',
  //   'w-auto-ns': fullWidth === 'sm',
  //   'w-100-l': fullWidth === 'lg',
  //   'w-100-m': fullWidth === 'md',
  // })

  return (
    <button
      onClick={(e) => {
        if (onClick) onClick(e)
        e.currentTarget.blur()
      }}
      className={`btn ${className}`}
      {...buttonProps}
      disabled={disabled}
    />
  )
}

export default Button
