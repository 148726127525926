import {
  Divider,
  LeftUIContainer,
  MagnifyingGlassIcon,
  UsernameContainer,
} from 'components'
import { RegistrationFlowHeader } from 'components/create-password/RegistrationFlowHeader'
import Button from 'components/Form/Button'
import { EmailDeconflictOptionalModal } from 'components/modals/EmailDeconflictOptionalModal'
import { EmailDeconflictBottomSheet } from 'components/sheets/EmailDeconflictBottomSheet'
import { useCookieContext } from 'contexts/CookieContext'
import { useAnalytics } from 'hooks'
import useBreakpoint from 'hooks/useBreakpoint'
import { AnalyticsEvent, AnalyticsPage, RegistrationFlow } from 'models'
import { useEffect, useRef, useState } from 'react'
import {
  COOKIE_EMAIL_KEY,
  COOKIE_REG_FLOW_KEY,
  FULL_MATCH_SUCCESS_ROUTE,
  ML_LOGIN_HOME,
  ML_REGISTRATION_HOME,
} from 'utilities'
import { motion, PanInfo, useAnimation } from 'framer-motion'
import useWindowSize from 'hooks/useWindowSize'

export function EmailDeconflictOptional() {
  const { page, trackClickEvent } = useAnalytics()
  const { removeCookie, setCookie } = useCookieContext()
  const { isDesktop, isPhone } = useBreakpoint()
  const [openModal, setOpenModal] = useState(false)
  const [openBottomSheet, setOpenBottomSheet] = useState(false)
  const { height } = useWindowSize()
  const analyticsPageName = AnalyticsPage.ExistingEmailAddressFound
  const loginBtnText = 'Log In With Existing Profile'
  const newProfileBtnText = 'Continue With My New Email'

  useEffect(() => {
    page(analyticsPageName)
    setCookie(COOKIE_REG_FLOW_KEY, RegistrationFlow.SsnDobMatch)
  }, [])

  const navigateToLogin = () => {
    removeCookie(COOKIE_EMAIL_KEY)
    trackClickEvent({
      event: `${analyticsPageName}: Log In With Existing Email`,
    })
    window.location.replace(
      `https://${ML_LOGIN_HOME}/?redirect=${ML_REGISTRATION_HOME}${FULL_MATCH_SUCCESS_ROUTE}`
    )
  }

  // Set default height size if undefined
  const screenHeight = height ?? window.innerHeight

  // Displays correct modal depending on screen size
  function displayModal(on: boolean) {
    isPhone ? setOpenBottomSheet(on) : setOpenModal(on)
    console.log('setting bottom sheet to open')
  }

  const modal = useRef<any>(null)
  const bottomSheet = useRef<any>(null)
  let currentModal = bottomSheet

  useEffect(() => {
    // Only add the click listener on non-mobile devices
    // On mobile, only support swipe to dismiss
    removeClickListener()
    if (!isPhone) {
      addClickListener()
    }
    currentModal = isPhone ? bottomSheet : modal
    // Transition from the bottomsheet to the modal, or vice versa, and
    // maintain the open state when the screen is resized
    if (isPhone && openModal) {
      setOpenModal(false)
      setOpenBottomSheet(true)
    } else if (!isPhone && openBottomSheet) {
      setOpenModal(true)
      setOpenBottomSheet(false)
    }
  }, [isPhone])

  function addClickListener() {
    document.addEventListener('click', handleOutsideClick, true)
  }

  function removeClickListener() {
    document.removeEventListener('click', handleOutsideClick, true)
  }

  // Closes modal when the user clicks outside of active area
  function handleOutsideClick(e: Event) {
    if (!currentModal.current?.contains(e.target)) {
      displayModal(false)
    }
  }

  const controls = useAnimation()

  const prevIsOpen = usePrevious(openBottomSheet)

  function usePrevious(value: any) {
    const previousValueRef = useRef()

    useEffect(() => {
      previousValueRef.current = value
    }, [value])

    return previousValueRef.current
  }

  useEffect(() => {
    if (prevIsOpen && !openBottomSheet) {
      void controls.start('hidden')
    } else {
      void controls.start('visible')
    }
  }, [controls, openBottomSheet, prevIsOpen])

  const onDragEnd = (_: Event, info: PanInfo) => {
    const shouldClose =
      info.velocity.y > 20 ||
      (info.velocity.y >= 0 && info.point.y > screenHeight - screenHeight / 5)

    if (shouldClose) {
      void controls.start('hidden')
      setOpenBottomSheet(false)
    } else {
      setOpenBottomSheet(true)
      void controls.start('visible')
    }
  }

  return (
    <>
      <div className={`${isPhone ? 'hidden' : 'modal-area'}`} ref={modal}>
        <EmailDeconflictOptionalModal
          open={openModal}
          onClose={() => {
            trackClickEvent({
              event: `${AnalyticsPage.CreateNewProfileModal}: ${AnalyticsEvent.Back}`,
            })
            setOpenModal(false)
          }}
        />
      </div>
      <section
        className={`relative ${
          isDesktop ? 'h-screen' : ''
        } grid max-h-screen grid-flow-row grid-cols-4 grid-rows-[min-content_1fr] overflow-hidden overscroll-none bg-white lg:auto-rows-fr lg:grid-cols-12 lg:grid-rows-none`}
      >
        <LeftUIContainer
          analyticsPage={analyticsPageName}
          icon="clipboard-question"
        />
        <div
          className={`col-span-8 h-screen overflow-scroll p-6 ${
            isDesktop ? 'm-auto pr-28' : ''
          }`}
        >
          <div className={`${isDesktop ? '' : 'hidden'}`}>
            <RegistrationFlowHeader
              isSuccessPage={false}
              title={''}
              analyticsPageName={analyticsPageName}
            />
          </div>
          <div>
            <div className="mx-auto px-4 pb-small lg:pb-large">
              <h3 className="pb-3 lg:block">
                {isDesktop
                  ? 'We found an existing email address for you!'
                  : 'Existing account found!'}
              </h3>
              <p className="text-standard text-grey md:pb-8">
                It looks like you already have a Mission Lane profile. Would you
                like to log in with the email address we have on file for you?
                {isDesktop
                  ? ' That way you can manage all of your accounts under one login.'
                  : ''}
              </p>
            </div>
            <div
              className={`mx-auto p-4 ${
                !isPhone
                  ? 'grid grid-cols-[1fr_min-content_1fr] grid-rows-1'
                  : ''
              }`}
            >
              <div
                className={`grid grid-cols-[1fr] ${
                  isPhone ? '' : 'grid-rows-[1fr_min-content_1fr]'
                }`}
              >
                <div>
                  <p className="text-standard-title text-ink lg:py-4">
                    Use existing profile
                  </p>
                  <p className="pt-2 text-standard text-ink-light">
                    If you’d like to keep one Mission Lane profile, please log
                    in with your existing email address.
                  </p>
                </div>
                <MagnifyingGlassIcon
                  className={`${
                    !isPhone ? 'mx-auto flex h-[152px] pt-6' : 'hidden'
                  }`}
                />
                <Button
                  className="my-6 whitespace-nowrap border-[3px] border-blue p-4 hover:border-indigo md:mt-16 lg:mt-16"
                  onClick={navigateToLogin}
                >
                  {loginBtnText}
                </Button>
              </div>
              <div>
                <Divider text="or" isVertical={!isPhone} />
              </div>
              <div className="grid grid-cols-[1fr] grid-rows-[1fr_min-content_1fr]">
                <div>
                  <p
                    className={`text-standard-title text-ink lg:py-4 ${
                      isPhone ? 'xs:pt-medium xs:pb-1 sm:pt-medium sm:pb-1' : ''
                    }`}
                  >
                    Create new profile
                  </p>
                  <p className="pt-2 text-standard text-ink-light">
                    This will prompt you to create a second profile with a
                    different password.
                    {isDesktop
                      ? ' You’ll need to keep up with both logins.'
                      : ''}
                  </p>
                </div>
                <UsernameContainer
                  className={`bg-blue-wash-light ${isPhone ? 'mt-4' : ''}`}
                />
                <Button
                  className="btn-outline my-6 whitespace-nowrap p-4 md:mt-16 lg:mt-16"
                  onClick={() => {
                    trackClickEvent({
                      event: `${analyticsPageName}: Continue with New Email`,
                    })
                    displayModal(true)
                  }}
                >
                  {newProfileBtnText}
                </Button>
                <div className={`${isPhone ? 'p-6' : 'hidden'}`}></div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${openBottomSheet || openModal ? 'overlay' : 'hidden'}`}
        ></div>
        <motion.div
          drag="y"
          dragConstraints={{ top: 0 }}
          onDragEnd={onDragEnd}
          initial={'hidden'}
          animate={controls}
          variants={{
            visible: { y: -65 },
            hidden: { y: '100%' },
          }}
          className={`${!isPhone ? 'hidden' : ''} z-40 w-screen`}
          ref={bottomSheet}
        >
          <EmailDeconflictBottomSheet
            open={openBottomSheet}
            onClose={() => {
              trackClickEvent({
                event: `${AnalyticsPage.CreateNewProfileBottomSheet}: ${AnalyticsEvent.Back}`,
              })
              setOpenBottomSheet(false)
            }}
            className="active"
          />
        </motion.div>
      </section>
    </>
  )
}
