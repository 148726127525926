import { useQuery } from '@tanstack/react-query'
import { useCookieContext } from 'contexts/CookieContext'
import { AnalyticsError } from 'models'
import { useNavigate } from 'react-router-dom'
import {
  buildRequest,
  COOKIE_REG_TOKEN_KEY,
  ERROR_ROUTE,
  isAuthError,
  isTimeoutError,
  TIMEOUT_ROUTE,
} from 'utilities'
import { useAnalytics } from './useAnalytics'

interface ReactQuery {
  key: string
  url: string
  method?: 'GET' | 'POST'
  role?: string
  body?: string
  enabled?: boolean
}

/**
 * Fetch data using react-query
 *
 * @param - key: a unique key to identify this request
 * @param - url: the URL to make the request to
 * @param - (optional) method: 'GET' | 'POST', defaults to 'GET'
 *
 * @returns - Query result information after request is complete
 */
export default function useReactQuery<T = {}>({
  key,
  url,
  method = 'GET',
  role,
  enabled = false,
}: ReactQuery) {
  const navigate = useNavigate()
  const { trackApiErrorEvent } = useAnalytics()
  const { getCookie } = useCookieContext()
  const registrationJwt = getCookie(COOKIE_REG_TOKEN_KEY)
  const { isLoading, error, data } = useQuery(
    [key],
    async () => {
      const request = buildRequest(method, registrationJwt, role)
      try {
        const res = await fetch(url, request)
        if (res.ok) {
          const data = await res.json()
          return data
        } else {
          // handle errors
          trackApiErrorEvent(AnalyticsError.Auth, url)
          if (isAuthError(res)) {
            navigate((await isTimeoutError(res)) ? TIMEOUT_ROUTE : ERROR_ROUTE)
          } else {
            // Get error description (for 404+ or possible 500 errors)
            throw new Error(res.statusText)
          }
        }
      } catch (e: any) {
        trackApiErrorEvent(e.message, url)
        throw new Error(e)
      }
    },
    { enabled }
  )

  return { isLoading, error, data: data as T }
}
