interface Props {
  className?: string
}

export function BottomSheetButton({ className = '', ...rest }: Props) {
  return (
    <svg
      width="41"
      height="8"
      viewBox="0 0 41 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <rect x="0.5" width="40" height="8" rx="4" fill="#CBD8D6" />
    </svg>
  )
}
