import { useMutation } from '@tanstack/react-query'
import { useCookieContext } from 'contexts/CookieContext'
import { AnalyticsError } from 'models'
import { useNavigate } from 'react-router-dom'
import {
  buildRequest,
  COOKIE_REG_TOKEN_KEY,
  ERROR_ROUTE,
  isAuthError,
  isTimeoutError,
  TIMEOUT_ROUTE,
} from 'utilities'
import { useAnalytics } from './useAnalytics'

interface ReactMutation {
  url: string
  body?: string
  method?: 'GET' | 'POST'
  role?: string
}

/**
 * Modify data with a mutation using react-query
 *
 * @param - url: the URL to make the request to
 * @param - body: the request body content
 * @param - method: 'GET' | 'POST', defaults to 'GET'
 *
 * @returns - Mutation result information after request is complete
 */

export default function useReactMutation({
  url,
  method = 'POST',
  role,
}: ReactMutation) {
  const navigate = useNavigate()
  const { trackApiErrorEvent } = useAnalytics()
  const { getCookie } = useCookieContext()
  const registrationJwt = getCookie(COOKIE_REG_TOKEN_KEY)
  return useMutation({
    mutationFn: async (body: string) => {
      const request = buildRequest(method, registrationJwt, role, body)
      try {
        const res = await fetch(url, request)
        if (res.status === 204) {
          // success, but no content
          return null
        }
        if (res.ok) {
          // success, with content
          const data = await res.json()
          return data
        }
        // handle errors
        trackApiErrorEvent(AnalyticsError.Auth, url)
        if (isAuthError(res)) {
          navigate((await isTimeoutError(res)) ? TIMEOUT_ROUTE : ERROR_ROUTE)
        } else {
          // Get error description (for 404+ or possible 500 errors)
          throw new Error(res.statusText)
        }
      } catch (e: any) {
        trackApiErrorEvent(e.message, url)
        throw new Error(e)
      }
    },
  })
}
