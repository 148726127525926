import useBreakpoint from 'hooks/useBreakpoint'
import useReactQuery from 'hooks/useReactQuery'
import { useCookieContext } from 'contexts/CookieContext'
import { useEffect } from 'react'
import { COOKIE_EMAIL_KEY, ML_LOGIN_HOME } from 'utilities'
import { Line } from './Line'

/**
 * Here's an example of using the useBreakpoint hook
 * to display different things when on desktop vs. mobile
 *
 * Sometimes the tailwind utility classes are not enough to
 * fully handle the customization we want at different screen sizes.
 * When that's the case, use the useBreakpoint hook.
 */
function UseBreakpointExample() {
  const { isDesktop } = useBreakpoint()
  return (
    <div
      className={`py-5 text-center text-white ${
        isDesktop ? 'bg-green' : 'bg-red'
      }`}
    >
      I&apos;m just a div and I&apos;m{' '}
      {isDesktop ? <>green on desktop</> : <>red on mobile</>}
    </div>
  )
}

/**
 * Example using the useReactQuery hook
 * to display a different loading, success, and error state
 * based on the current state of a network call
 */
function UseReactQueryExample() {
  const { isLoading, error, data } = useReactQuery<any>({
    key: 'playground-weather-test',
    url: 'https://api.open-meteo.com/v1/forecast?latitude=40.71&longitude=-74.01&current_weather=true&temperature_unit=fahrenheit',
    role: 'system',
  })
  return (
    <>
      {isLoading && <div>Loading weather data...</div>}
      {error && <div>Error: {JSON.stringify(error)}</div>}
      {data && (
        <>
          <h2>Weather data</h2>
          <table className="text-left">
            <tbody>
              <tr className="odd:bg-blue-100 even:bg-amber-100">
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Temperature</th>
              </tr>
              <tr className="odd:bg-blue-100 even:bg-amber-100">
                <td>{data.latitude}</td>
                <td>{data.longitude}</td>
                <td>{data.current_weather.temperature} F</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </>
  )
}

/**
 * Example that sets a value in an 'email' cookie and then redirects over
 * to the login application to see if the cookie persists and the login
 * app handles the situation correctly.
 *
 * The login application should grab the email value from the cookie,
 * save it in storage, redirect to the '/authorize' route, and hide the
 * change accounts link
 */
function EmailCookieExample() {
  const { setCookie } = useCookieContext()
  useEffect(() => {
    setCookie(COOKIE_EMAIL_KEY, 'testfromreg@gmail.com')
  }, [])
  return <a href={ML_LOGIN_HOME}>Go to Login</a>
}

export function Playground() {
  return (
    <section className="mx-8 grid max-w-4xl gap-5 pt-5 lg:mx-auto">
      <h1>Playground</h1>
      <Line />
      <UseBreakpointExample />
      <Line />
      <UseReactQueryExample />
      <Line />
      <EmailCookieExample />
    </section>
  )
}
