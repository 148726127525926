import Button from 'components/Form/Button'
import { useCookieContext } from 'contexts/CookieContext'
import { useNavigate } from 'react-router-dom'
import { COOKIE_PRODUCT_REDIRECT_KEY, ERROR_ROUTE } from 'utilities'

export function CtaContent() {
  const navigate = useNavigate()
  const { getCookie } = useCookieContext()
  const redirectUrl = getCookie(COOKIE_PRODUCT_REDIRECT_KEY) ?? ERROR_ROUTE
  return (
    <Button className="mt-0" onClick={() => navigate(redirectUrl)}>
      Log In
    </Button>
  )
}
