interface Props {
  className?: string
}

export function HelpIcon({ className = '', ...rest }: Props) {
  return (
    <svg
      width="13"
      height="19"
      viewBox="0 0 13 19"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path d="M6.60156 0.25C3.78906 0.25 1.96094 1.41016 0.519531 3.48438C0.273438 3.83594 0.34375 4.36328 0.730469 4.60938L2.24219 5.76953C2.59375 6.05078 3.12109 5.98047 3.40234 5.62891C4.28125 4.53906 4.94922 3.90625 6.32031 3.90625C7.41016 3.90625 8.74609 4.57422 8.74609 5.62891C8.74609 6.4375 8.07812 6.82422 7.02344 7.42188C5.75781 8.125 4.10547 9.00391 4.10547 11.1836V11.5C4.10547 11.9922 4.49219 12.3438 4.94922 12.3438H7.51562C7.97266 12.3438 8.35938 11.9922 8.35938 11.5V11.3242C8.35938 9.8125 12.7539 9.74219 12.7539 5.66406C12.7539 2.60547 9.55469 0.25 6.60156 0.25ZM6.25 13.3984C4.87891 13.3984 3.78906 14.4883 3.78906 15.8242C3.78906 17.1602 4.87891 18.25 6.25 18.25C7.58594 18.25 8.67578 17.1602 8.67578 15.8242C8.67578 14.4883 7.58594 13.3984 6.25 13.3984Z" />
    </svg>
  )
}
