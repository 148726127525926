export const ERROR_BORDER_STYLE = 'border-red-600 border-2'
export const NORMAL_BORDER_STYLE = 'b--lightest-ink border'

export const ERROR_BACKGROUND_COLOR = 'bg-washed-haze'
export const NORMAL_BACKGROUND_COLOR = 'bg-washed-haze'

// Focused border styles implemented with CSS pseudo-selector
export const PSEUDO_FOCUS_BORDER_COLOR = 'focus-b--blue'

// Explicitly sets the border color to the focused styles without a pseudo-selector.
// Useful if focused state is managed in state, so pseudo-selector doesn't work.
export const EXPLICIT_FOCUS_BORDER_COLOR = 'b--blue'
