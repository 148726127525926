interface Props {
  className?: string
}

export function UserIcon({ className = '', ...rest }: Props) {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path
        d="M54 27C54 41.9117 41.9117 54 27 54C12.0883 54 0 41.9117 0 27C0 12.0883 12.0883 0 27 0C41.9117 0 54 12.0883 54 27Z"
        fill="#005CC4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7204 29.8871C22.5018 29.1057 23.5616 28.6667 24.6667 28.6667H31.3333C32.4384 28.6667 33.4982 29.1057 34.2796 29.8871C35.061 30.6685 35.5 31.7283 35.5 32.8333V34.5C35.5 34.9602 35.1269 35.3333 34.6667 35.3333C34.2064 35.3333 33.8333 34.9602 33.8333 34.5V32.8333C33.8333 32.1703 33.5699 31.5344 33.1011 31.0656C32.6323 30.5967 31.9964 30.3333 31.3333 30.3333H24.6667C24.0036 30.3333 23.3677 30.5967 22.8989 31.0656C22.4301 31.5344 22.1667 32.1703 22.1667 32.8333V34.5C22.1667 34.9602 21.7936 35.3333 21.3333 35.3333C20.8731 35.3333 20.5 34.9602 20.5 34.5V32.8333C20.5 31.7283 20.939 30.6685 21.7204 29.8871Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 20.3333C26.6193 20.3333 25.5 21.4526 25.5 22.8333C25.5 24.214 26.6193 25.3333 28 25.3333C29.3807 25.3333 30.5 24.214 30.5 22.8333C30.5 21.4526 29.3807 20.3333 28 20.3333ZM23.8333 22.8333C23.8333 20.5321 25.6988 18.6667 28 18.6667C30.3012 18.6667 32.1667 20.5321 32.1667 22.8333C32.1667 25.1345 30.3012 27 28 27C25.6988 27 23.8333 25.1345 23.8333 22.8333Z"
        fill="white"
      />
    </svg>
  )
}
