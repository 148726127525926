interface Props {
  className?: string
}

export function PuzzlePeopleIcon({ className = '' }: Props) {
  return (
    <svg
      width="390"
      height="258"
      viewBox="0 0 390 258"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M108.261 242.076C109.088 242.685 109.61 243.686 109.871 244.686C110.132 245.687 110.132 246.732 110.176 247.776C110.219 250.561 110.263 253.476 108.871 255.913C108.479 256.565 107.826 257.262 107.087 257.131C106.303 257.001 105.955 256.13 105.738 255.391C104.65 251.518 104.65 247.167 102.387 243.86C103.562 243.599 104.693 243.12 105.651 242.38C106.042 242.119 106.39 241.771 106.869 241.684C107.348 241.554 107.87 241.771 108.261 242.076Z"
        fill="#072D26"
      />
      <path
        d="M63.4433 224.54C63.9654 224.627 64.4005 225.019 64.7051 225.454C65.0097 225.889 65.0967 226.455 65.2273 226.977C65.5319 228.195 65.7929 229.413 66.0975 230.632C66.2716 231.285 66.4021 232.024 66.054 232.59C65.7494 233.069 65.1838 233.286 64.6181 233.46C60.4844 234.809 56.1331 235.375 51.7818 235.07C51.6948 232.459 53.8704 231.72 57.5255 229.544C58.3087 229.065 59.353 229.109 60.0492 228.456C60.7454 227.847 60.963 226.89 61.3546 226.019C61.7898 225.236 62.5295 224.41 63.4433 224.54Z"
        fill="#072D26"
      />
      <path
        d="M1.37228 33.4125C0.62532 31.8528 1.33556 29.9854 2.93025 29.3162L66.2868 2.73149C68.7395 1.70232 71.231 4.11171 70.2846 6.59752L66.9977 15.2306C66.4033 16.792 67.1871 18.5398 68.7485 19.1343L141.46 46.8197C144.941 48.1251 146.681 51.9978 145.332 55.4788L125.142 108.521C123.837 112.002 119.964 113.742 116.483 112.394L101.993 106.867C100.166 106.171 99.8178 103.691 101.428 102.603C103.734 101.037 105.562 98.7305 106.562 95.8587C108.825 89.2447 105.562 81.8476 99.1216 79.1498C92.0726 76.1474 84.0227 79.6284 81.2814 86.721C80.2806 89.3753 80.1501 92.1166 80.8028 94.6838C81.2814 96.5984 79.4103 98.2519 77.5828 97.5557L43.7713 84.7057C42.2101 84.1124 40.4635 84.8964 39.8692 86.4572L36.5795 95.098C35.6334 97.5829 32.1721 97.7264 31.0237 95.3283L1.37228 33.4125Z"
        fill="#007459"
      />
      <path
        d="M84.4154 177.01C88.8537 179.707 90.9858 185.321 91.682 190.76C92.3782 196.199 91.9431 201.768 92.6828 207.164C94.4668 219.739 102.386 230.008 107.869 241.322C108.521 242.67 108.173 244.324 107.042 245.281L106.999 245.325C105.606 246.456 103.561 246.282 102.43 244.89C97.0341 238.363 91.7691 231.662 86.6781 224.83C84.1108 221.393 81.587 217.868 79.8465 213.865C77.8885 209.253 77.0182 204.205 75.7563 199.332C73.7547 191.63 70.6653 184.32 67.5759 177.053C73.1456 175.443 79.4549 173.964 84.4154 177.01Z"
        fill="#007459"
      />
      <path
        d="M75.3647 182.144C70.0562 188.454 64.7911 194.763 59.4826 201.072C58.6123 202.117 57.655 203.248 57.3939 204.64C57.0458 206.337 57.7855 207.991 58.4817 209.557C60.2658 213.56 62.0498 217.52 63.8338 221.523C64.3995 222.785 65.0087 224.178 64.7476 225.57C64.4865 227.093 63.3552 228.137 62.0063 228.964C60.9184 229.617 59.5261 229.356 58.8299 228.311C54.1305 221.567 49.9532 214.431 46.2982 207.077C44.5141 203.465 44.7317 199.201 46.8203 195.807C52.0853 187.235 56.5237 178.141 60.0482 168.699C65.0957 171.788 70.1432 174.834 75.1472 177.923L75.3647 182.144Z"
        fill="#007459"
      />
      <path
        d="M65.1815 136.717C62.8753 136.848 60.5692 133.019 59.1332 131.191C57.6973 129.364 55.6087 122.489 54.9995 120.226C52.1712 109.696 53.259 102.429 55.1736 91.7251C56.9141 91.9427 58.6546 92.2038 60.4386 92.4213C58.9592 100.428 59.873 108.826 63.0494 116.31C64.8769 120.618 67.5747 124.751 71.7519 126.84C71.0122 128.406 70.142 129.929 68.9236 131.191C67.6617 132.453 66.922 136.63 65.1815 136.717Z"
        fill="#072D26"
      />
      <path
        d="M58.3066 173.181C58.3066 173.181 77.191 186.104 85.1104 186.539C88.3303 186.714 90.7671 183.668 89.9403 180.578C88.5044 175.27 88.3303 169.482 88.983 163.913C89.8968 156.168 92.29 148.683 94.6832 141.243C85.8066 133.976 76.1467 127.841 65.9647 122.924C59.2638 137.805 56.6965 154.819 58.7416 171.092L58.3066 173.181Z"
        fill="#072D26"
      />
      <path
        d="M87.7211 147.204C86.7203 146.16 85.7195 145.028 85.502 143.592C85.2409 141.721 86.4593 139.85 88.0692 138.806C89.6792 137.762 91.5503 137.327 93.4213 136.848C96.1626 136.108 98.9039 135.194 101.515 134.063C102.69 133.584 103.864 133.019 104.735 132.148C105.692 131.191 106.301 129.929 106.867 128.667C109.173 123.533 111.305 118.311 113.263 113.046C113.394 112.655 113.611 112.22 114.003 112.089C114.307 112.002 114.656 112.133 114.96 112.263C116.179 112.829 117.353 113.351 118.572 113.917C116.353 121.401 114.133 128.842 111.914 136.326C111.653 137.196 111.392 138.066 110.87 138.849C110.261 139.763 109.303 140.372 108.39 140.938C104.256 143.549 99.9918 145.986 95.6405 148.248C94.2481 148.988 92.7686 149.684 91.2457 149.423C89.8098 149.206 88.6784 148.205 87.7211 147.204Z"
        fill="#072D26"
      />
      <path
        d="M74.3396 133.859L74.3427 133.863C74.7099 134.451 75.1963 134.667 75.8565 134.665C76.5907 134.664 77.4943 134.381 78.4447 133.92C79.3752 133.469 80.2511 132.897 80.9029 132.43C81.1788 132.232 81.4106 132.055 81.5848 131.919L81.903 128.174L75.9431 126.218C75.893 126.292 75.8399 126.372 75.7845 126.457C75.4177 127.02 74.9578 127.8 74.5721 128.683C73.7655 130.53 73.4449 132.442 74.3396 133.859Z"
        fill="#F9F8F7"
        stroke="#072D26"
        strokeWidth="2.26894"
      />
      <path
        d="M114.213 109.149L114.221 109.145L114.229 109.141C115.108 108.742 116.081 108.925 116.771 109.289C117.251 109.513 117.71 109.845 118.049 110.091C118.126 110.146 118.197 110.198 118.26 110.242C118.672 110.524 118.863 110.64 119.04 110.716C119.192 110.782 119.374 110.836 119.753 110.909C120.192 110.89 120.703 110.742 121.208 110.546C121.439 110.457 121.645 110.368 121.835 110.285C121.862 110.274 121.888 110.262 121.913 110.251C122.013 110.208 122.12 110.162 122.216 110.125L122.217 110.125C122.283 110.099 122.441 110.038 122.616 110.011C123.246 109.91 123.727 110.237 123.975 110.498C124.229 110.766 124.449 111.175 124.449 111.654C124.449 112.258 124.162 112.751 123.967 113.047C123.721 113.448 123.401 113.939 122.994 114.367C122.576 114.806 122.012 115.235 121.271 115.411L121.259 115.414L121.246 115.417C120.765 115.52 120.319 115.495 119.954 115.45C119.772 115.427 119.595 115.397 119.443 115.37C119.426 115.367 119.409 115.364 119.392 115.362C119.255 115.338 119.137 115.317 119.02 115.3C118.664 115.25 118.299 115.229 117.876 115.204C117.825 115.202 117.774 115.199 117.722 115.196C117.255 115.168 116.721 115.133 116.184 115.031C115.155 114.835 113.85 114.351 113.122 113.159L113.109 113.139L113.098 113.118C112.729 112.453 112.657 111.662 112.815 110.971C112.974 110.277 113.406 109.531 114.213 109.149Z"
        fill="#F9F8F7"
        stroke="#072D26"
        strokeWidth="2.26894"
      />
      <path
        d="M51.8197 85.6184L51.872 85.5464L51.935 85.4835C52.08 85.3384 52.3195 85.1425 52.6588 85.0596C53.0534 84.9631 53.4314 85.0558 53.7207 85.253C53.9677 85.4213 54.1122 85.6376 54.1897 85.7725C54.2735 85.9185 54.3333 86.0664 54.3759 86.1849C54.4431 86.372 54.5027 86.5903 54.5459 86.7487C54.5548 86.7814 54.5631 86.8115 54.5705 86.8383C54.6124 86.9889 54.6366 87.0591 54.645 87.0833C54.6467 87.0885 54.6478 87.0916 54.6482 87.0929C54.9706 87.6408 55.5615 88.0219 56.374 88.2338L56.403 88.2414L56.4316 88.2505C56.8349 88.3788 57.2717 88.466 57.7732 88.5587C57.8238 88.568 57.8753 88.5774 57.9275 88.587C58.3699 88.6682 58.866 88.7591 59.345 88.8898L59.3754 88.8981L59.4052 88.908C59.4187 88.9125 59.4323 88.917 59.4461 88.9216C59.8122 89.0434 60.3148 89.2107 60.7791 89.5009L60.8514 89.5461L60.9161 89.6016C61.2513 89.8889 61.8632 90.4852 61.958 91.3384C62.0442 92.1143 61.7355 92.7918 61.3149 93.2662C60.8988 93.7354 60.3143 94.0787 59.6788 94.1909C58.6172 94.3782 57.5904 94.0619 56.8245 93.7288C55.6506 93.2575 54.5322 92.6301 53.5447 91.7413C52.5578 90.8531 51.6886 89.6508 51.4078 88.2087C51.2563 87.4944 51.1882 86.4867 51.8197 85.6184Z"
        fill="#F9F8F7"
        stroke="#072D26"
        strokeWidth="2.26894"
      />
      <path
        d="M72.6657 116.385C71.7369 115.708 71.4148 114.649 71.7834 113.92C72.0648 113.375 72.6474 113.164 72.8616 113.097C72.0084 111.299 72.0667 109.864 72.7109 109.506C73.2211 109.227 74.0738 109.717 74.411 109.94C73.8763 108.5 74.4954 107.299 75.1931 107.144C75.7849 107.013 76.6669 107.585 77.1377 108.607C76.7686 107.736 77.1306 106.909 77.7158 106.679C78.2354 106.479 78.7801 106.829 78.8551 106.878C79.1673 106.259 79.7366 105.852 80.2815 105.909C81.0299 105.975 81.7979 106.922 81.5107 108.09C82.2249 106.943 83.3913 106.658 83.8758 106.999C84.288 107.271 84.3732 108.122 83.9912 108.946C86.1662 107.75 88.153 107.893 88.6961 108.691C89.155 109.36 88.5921 110.451 88.4031 110.775C88.9414 110.734 89.3631 110.793 89.6416 110.852C90.0177 110.944 90.256 110.997 90.3925 111.191C90.6654 111.581 90.2929 112.192 90.2647 112.246C91.4714 111.675 91.826 111.92 91.9183 111.991C92.3774 112.368 92.0166 113.624 91.0624 115.167C91.8135 115.214 92.057 115.521 92.1065 115.606C92.5012 116.149 92.1538 117.31 90.9216 118.209C90.9883 118.609 91.0095 119.041 91.0052 119.508C90.9319 121.487 89.9858 122.973 89.3856 123.747C88.0432 125.431 86.696 125.982 85.8698 126.178C81.8018 127.147 76.1658 123.391 72.6657 116.385Z"
        fill="#072D26"
      />
      <path
        d="M87.5079 123.437C87.3898 124.369 86.5378 125.199 85.5295 125.192C85.4888 125.191 85.448 125.204 85.4118 125.227C85.3757 125.251 85.3458 125.285 85.3256 125.326C83.3315 129.315 79.5449 131.293 76.7273 130.386C72.988 129.182 72.1532 123.279 73.5947 119.27C75.0898 115.114 79.6797 111.201 83.2342 112.842C85.6888 113.974 87.1042 117.489 86.5518 121.431C86.545 121.478 86.5513 121.526 86.5697 121.567C86.5881 121.608 86.6177 121.641 86.6548 121.662C86.94 121.827 87.1714 122.082 87.3223 122.396C87.4733 122.71 87.5376 123.071 87.5079 123.437Z"
        fill="#F9F8F7"
        stroke="#072D26"
        strokeWidth="2.26894"
        strokeMiterlimit="10"
      />
      <path
        d="M235.343 131.339L235.343 131.339L235.352 131.332C235.537 131.195 235.721 131.035 235.931 130.845C235.969 130.81 236.01 130.773 236.051 130.736C236.221 130.581 236.413 130.407 236.605 130.247C237.084 129.849 237.726 129.4 238.575 129.272C239.56 129.104 240.487 129.461 241.157 129.992C241.83 130.525 242.368 131.327 242.497 132.244C242.733 133.923 241.799 135.537 240.473 136.416C239.199 137.262 237.699 137.563 236.266 137.504C233.104 137.441 230.09 135.919 228.092 133.531C227.788 133.207 227.638 132.778 227.638 132.402C227.638 131.78 228.03 131.372 228.336 131.182C228.649 130.987 229.081 130.873 229.531 130.982L229.265 132.081L229.531 130.982C229.944 131.083 230.336 131.352 230.452 131.431C230.465 131.44 230.474 131.446 230.48 131.45L230.489 131.456L230.499 131.463C231.901 132.41 233.988 132.366 235.343 131.339Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="2.26894"
      />
      <path
        d="M174.197 132.747C172.278 131.467 170.359 130.188 168.44 128.909C168.257 128.771 165.79 136.63 165.607 137.316C164.191 142.57 162.226 151.891 167.207 155.912C170.907 158.881 176.345 159.75 180.914 160.161C181.827 160.252 190.829 160.526 190.874 160.069C191.331 156.597 191.514 153.079 191.422 149.561C188.59 150.794 185.757 150.931 182.696 151.251C179.177 151.571 174.38 151.251 171.639 148.692C169.902 147.048 170.953 142.798 171.319 140.651C171.776 137.955 172.415 134.848 174.197 132.747Z"
        fill="#007459"
      />
      <path
        d="M248.983 105.3C245.741 106.14 242.466 105.88 239.615 104.776C237.53 103.961 235.389 105.724 235.849 107.89L239.4 124.593C240.269 128.685 237.654 132.712 233.563 133.582L170.313 147.026C166.221 147.896 162.194 145.28 161.324 141.189L147.993 78.4684C147.123 74.3769 149.738 70.3496 153.83 69.4799L171.014 65.8273C173.18 65.3669 175.002 67.5455 174.167 69.5342C172.928 72.4639 172.62 75.7492 173.422 79.0501C175.31 86.7487 182.956 91.7141 190.767 90.3557C199.311 88.8919 204.848 80.6212 203.057 72.1976C202.392 69.0688 200.805 66.3371 198.619 64.3366C196.989 62.8216 197.767 60.0905 199.933 59.6301L217.118 55.9774C221.209 55.1078 225.236 57.7231 226.106 61.8146L229.667 78.5657C230.127 80.7318 232.8 81.4717 234.373 79.8795C236.404 77.8379 239.002 76.3299 242.034 75.6853C250.458 73.8948 258.68 79.442 260.192 87.9752C261.444 95.7587 256.565 103.336 248.983 105.3Z"
        fill="#64958B"
      />
      <path
        d="M218.104 246.653C216.962 246.561 214.312 246.378 213.261 245.921C212.21 245.465 213.169 244.505 213.078 243.363C212.941 242.084 213.489 240.941 214.677 240.484C215.738 240.076 216.945 240.179 218.005 240.563C218.253 240.653 218.459 240.826 218.623 241.031C220.314 243.145 222.804 244.614 225.46 244.962C225.963 245.053 226.465 245.053 226.922 245.328C227.379 245.556 227.699 246.104 227.516 246.561C227.288 247.247 226.374 247.292 225.688 247.247C223.175 247.064 220.617 246.835 218.104 246.653Z"
        fill="#007459"
      />
      <path
        d="M206.532 188.609C207.31 186.634 209.827 186.054 211.391 187.488L233.87 208.09C235.972 210.009 236.429 213.162 235.012 215.583L220.46 240.573C219.794 241.716 218.462 242.294 217.172 242L216.796 241.914C215.051 241.515 214.032 239.698 214.602 238.001L221.105 218.635C221.524 217.388 221.089 216.014 220.029 215.235L203.306 202.936C202.2 202.123 201.78 200.667 202.284 199.39L206.532 188.609Z"
        fill="#072D26"
      />
      <path
        d="M208.419 185.656C208.83 186.25 208.875 186.981 208.875 187.712C208.967 193.926 209.104 200.186 209.195 206.4C200.514 202.881 191.102 201.191 181.735 201.465C182.147 196.348 182.558 191.23 182.969 186.067C190.28 182.595 198.915 182.001 206.591 184.514C207.276 184.788 208.007 185.062 208.419 185.656Z"
        fill="#072D26"
      />
      <path
        d="M188.681 181.726C187.905 180.31 188.225 178.528 188.727 177.02C189.641 174.279 191.149 171.766 192.382 169.116C194.21 165.232 195.444 161.12 196.083 156.871C196.357 155.18 196.494 153.444 197.134 151.799C197.774 150.2 198.962 148.692 200.652 148.235C201.749 147.916 201.778 148.327 202.92 148.555C205.251 149.012 208.877 147.779 211.161 148.555C212.212 148.921 213.537 150.017 213.994 150.977C214.451 151.891 213.857 153.947 213.628 154.906C212.258 160.8 210.339 166.557 208.008 172.131C206.455 175.878 204.627 179.853 205.587 183.783C205.815 184.788 206.227 185.884 205.678 186.752C205.084 187.666 203.714 187.666 202.617 187.529C198.779 186.981 195.032 185.793 191.56 184.057C190.418 183.508 189.275 182.823 188.681 181.726Z"
        fill="#072D26"
      />
      <path
        d="M157.479 240.388C157.723 239.915 158.198 239.582 158.73 239.605C159.444 239.635 160.159 239.673 160.873 239.705C161.583 239.737 162.186 240.251 162.283 240.954C162.447 242.143 162.528 243.341 162.527 244.529C162.527 245.215 162.033 245.797 161.357 245.919C156.824 246.738 152.163 246.659 147.696 245.648C147.46 245.608 147.191 245.501 147.062 245.298C147.02 245.231 146.999 245.151 147.01 245.072C147.043 244.839 147.272 244.616 147.468 244.46C149.984 242.74 153.192 241.252 156.281 241.125C156.782 241.104 157.249 240.833 157.479 240.388Z"
        fill="#007459"
      />
      <path
        d="M185.118 186.272C186.743 185.459 188.714 186.254 189.322 187.967L192.821 197.831C193.434 199.56 192.381 201.432 190.585 201.805L171.106 205.851C169.907 206.1 168.976 207.047 168.749 208.25L163.142 237.864C162.854 239.381 161.471 240.438 159.932 240.317L159.779 240.305C158.142 240.176 156.906 238.764 156.996 237.124L158.936 201.739C159.027 200.231 159.895 198.906 161.22 198.221L185.118 186.272Z"
        fill="#072D26"
      />
      <path
        d="M212.175 181.646C211.206 182.838 211.285 184.566 212.357 185.666L218.012 191.462C219.585 193.074 218.907 195.779 216.76 196.459L205.884 199.901C203.816 200.555 201.751 198.885 201.96 196.726L203.336 182.454C203.36 182.205 203.415 181.96 203.499 181.725L213.227 154.552C213.743 153.112 215.258 152.271 216.727 152.7C220.387 153.769 223.487 155.199 227.327 157.004C229.114 157.843 229.644 160.142 228.399 161.674L212.175 181.646Z"
        fill="#007459"
      />
      <path
        d="M198.005 148.048C199.395 147.958 200.166 149.337 199.929 150.71L195.12 178.618L194.249 196.535C194.143 198.7 191.863 200.055 189.912 199.113L178.659 193.681C177.018 192.889 176.445 190.838 177.436 189.31L185.185 177.367C185.411 177.018 185.563 176.625 185.631 176.215L189.701 151.635C189.953 150.115 191.313 149.044 192.843 148.863C194.797 148.631 196.51 148.145 198.005 148.048Z"
        fill="#007459"
      />
      <path
        d="M213.405 145.596L214.379 146.017L214.024 147.017L211.697 153.59L211.613 153.828L211.437 154.007C211.372 154.073 211.313 154.116 211.292 154.131C211.264 154.152 211.241 154.167 211.227 154.175C211.199 154.193 211.176 154.206 211.164 154.212C211.14 154.225 211.119 154.235 211.107 154.241C211.081 154.254 211.054 154.267 211.028 154.278C210.976 154.302 210.907 154.332 210.826 154.367C210.661 154.437 210.433 154.532 210.158 154.641C209.611 154.858 208.868 155.135 208.08 155.386C207.3 155.635 206.44 155.869 205.667 155.985C205.282 156.042 204.884 156.076 204.509 156.055C204.154 156.035 203.703 155.961 203.306 155.705L203.299 155.7L203.292 155.696C201.695 154.633 201.056 152.527 202.077 150.454L205.214 143.291L205.667 142.258L206.703 142.705L213.405 145.596Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="2.26894"
      />
      <path
        d="M236.789 140.379C235.313 139.097 235.451 136.847 237.112 135.816C238.263 135.103 239.501 134.342 240.585 133.664C241.91 132.835 243.681 133.078 244.579 134.356C247.364 138.317 249.229 142.769 250.728 147.459C252.464 153.033 251.779 159.795 247.484 163.77C244.88 166.146 241.407 167.243 237.935 167.928C233.489 168.824 228.97 169.166 224.45 169.338C222.151 169.426 220.645 166.991 221.715 164.954C222.065 164.288 222.416 163.62 222.766 162.948C223.542 161.486 224.411 159.932 225.918 159.247C227.335 158.607 228.934 158.881 230.487 159.064C234.006 159.475 238.026 159.201 240.357 156.551C242.458 154.221 242.641 150.703 241.956 147.641C241.342 144.64 239.141 142.422 236.789 140.379Z"
        fill="#007459"
      />
      <path
        d="M207.443 125.864C207.158 124.812 206.836 124.073 206.238 124.984C205.507 126.097 204.979 127.554 205.126 128.716C205.126 128.716 205.137 128.288 205.108 127.816C205.052 126.907 204.424 127.048 204.098 127.899C203.752 128.804 203.403 129.985 203.562 130.769C203.852 132.252 205.184 133.336 206.632 133.906C208.022 134.477 209.585 134.648 211.091 135.104C212.539 135.503 214.044 136.245 214.855 137.5C215.203 138.013 215.376 138.64 215.724 139.154C216.071 139.667 216.593 140.181 217.23 140.181C217.809 140.238 218.33 139.895 218.677 139.439C219.025 139.04 219.199 138.469 219.43 137.956C219.836 136.872 220.299 135.732 220.357 134.534C220.473 133.336 220.241 132.081 219.43 131.225C218.735 130.427 217.693 129.971 216.65 129.8C215.608 129.571 214.508 129.628 213.465 129.514C212.423 129.4 211.323 129.229 210.396 128.659C209.469 128.088 208.775 127.176 208.775 126.092C208.775 124.956 208.785 123.916 208.012 124.749C207.72 125.063 207.513 125.445 207.443 125.864Z"
        fill="#072D26"
      />
      <path
        d="M218.102 142.329C217.984 143.263 217.13 144.094 216.12 144.087C216.079 144.086 216.038 144.099 216.002 144.122C215.966 144.146 215.936 144.18 215.916 144.221C213.918 148.218 210.124 150.2 207.3 149.292C203.554 148.084 202.717 142.171 204.162 138.154C205.66 133.989 210.259 130.069 213.82 131.712C216.28 132.847 217.698 136.369 217.144 140.319C217.138 140.366 217.144 140.413 217.162 140.455C217.181 140.496 217.21 140.529 217.247 140.55C217.533 140.716 217.765 140.971 217.916 141.285C218.068 141.6 218.132 141.962 218.102 142.329Z"
        fill="#F9F8F7"
        stroke="#072D26"
        strokeWidth="2.26894"
        strokeMiterlimit="10"
      />
      <path
        d="M331.163 97.6991C331.277 97.8537 331.328 98.0464 331.304 98.2372C331.296 98.3221 331.275 98.4053 331.242 98.4842C331.242 98.6077 331.127 98.74 331.074 98.8546L330.871 99.2075C330.809 99.3221 330.747 99.4192 330.703 99.5074L330.65 99.6044L330.571 99.6838C330.351 99.8944 330.213 100.176 330.182 100.478V100.566C330.049 101.122 329.89 101.677 329.704 102.215C329.569 102.49 329.396 102.746 329.192 102.974L329.121 103.997V104.615H324.976L324.835 104.156L324.711 103.777C324.418 103.501 324.144 103.206 323.889 102.895C323.428 102.251 323.07 101.54 322.829 100.786C322.652 100.274 322.545 99.7396 322.51 99.1987C322.29 98.1284 322.201 97.0354 322.245 95.9438C322.239 95.894 322.239 95.8436 322.245 95.7938C322.237 95.7412 322.237 95.6876 322.245 95.635C322.267 95.5261 322.299 95.4197 322.343 95.3175C322.386 95.1971 322.442 95.0817 322.51 94.9735C322.561 94.8992 322.621 94.8311 322.687 94.7706L322.873 94.6471C322.888 94.4419 322.921 94.2383 322.97 94.0385C323.003 93.8865 323.06 93.7407 323.138 93.6062C323.198 93.4928 323.282 93.3938 323.384 93.3161C323.487 93.2384 323.605 93.1839 323.73 93.1564C323.748 93.0544 323.775 92.9541 323.81 92.8565C323.871 92.6567 323.986 92.4774 324.142 92.3376C324.297 92.1978 324.488 92.1028 324.693 92.0626C324.888 92.0241 325.089 92.0373 325.277 92.1007C325.464 92.1642 325.632 92.2758 325.763 92.4242C325.832 92.4996 325.897 92.5792 325.957 92.6624C326.171 92.6384 326.388 92.6715 326.585 92.7583C326.782 92.8451 326.953 92.9824 327.08 93.1564C327.254 93.3699 327.397 93.6076 327.504 93.862C327.636 94.1972 328.131 95.6262 328.326 96.279C328.423 96.6053 328.582 97.2845 328.715 97.8844L328.776 97.8226C328.973 97.6035 329.222 97.4371 329.5 97.3385C329.778 97.2399 330.076 97.2123 330.367 97.2581C330.673 97.3235 330.946 97.4955 331.136 97.7432L331.163 97.6991Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="2.26894"
      />
      <path
        d="M345.878 91.6748C345.993 91.8294 346.044 92.0221 346.02 92.2129C346.013 92.298 345.992 92.3815 345.958 92.4599C345.911 92.5873 345.855 92.7111 345.79 92.8303L345.596 93.1832L345.419 93.4831L345.366 93.5801L345.286 93.6595C345.068 93.871 344.931 94.1517 344.897 94.4534V94.5416C344.77 95.1058 344.611 95.6625 344.42 96.2087C344.285 96.4837 344.112 96.7389 343.908 96.9673L343.837 97.9905V98.608H339.718L339.577 98.1493L339.453 97.7788C339.163 97.5004 338.889 97.2059 338.631 96.8968C338.173 96.2323 337.829 95.4959 337.615 94.718C337.436 94.2026 337.326 93.6657 337.288 93.1214C337.067 92.0513 336.978 90.9582 337.023 89.8665C337.017 89.8197 337.017 89.7723 337.023 89.7254C337.014 89.6699 337.014 89.6133 337.023 89.5578C337.044 89.4489 337.077 89.3425 337.12 89.2403C337.163 89.1198 337.22 89.0045 337.288 88.8962C337.339 88.822 337.398 88.7539 337.465 88.6934L337.65 88.5699C337.667 88.3648 337.7 88.1614 337.748 87.9612C337.781 87.8093 337.837 87.6635 337.916 87.529C337.976 87.4156 338.06 87.3165 338.162 87.2388C338.264 87.1611 338.382 87.1066 338.508 87.0791C338.525 86.9741 338.551 86.8707 338.587 86.7704C338.65 86.5712 338.765 86.3923 338.92 86.2527C339.076 86.113 339.266 86.0176 339.471 85.9765C339.666 85.9372 339.868 85.9509 340.056 86.016C340.244 86.0812 340.412 86.1955 340.54 86.347C340.612 86.4171 340.678 86.4939 340.735 86.5764C340.949 86.5533 341.165 86.5868 341.362 86.6735C341.559 86.7602 341.73 86.897 341.857 87.0703C342.034 87.2855 342.177 87.5265 342.281 87.7848C342.414 88.1112 342.909 89.549 343.103 90.1929C343.201 90.5193 343.36 91.1985 343.492 91.7983L343.554 91.7366C343.741 91.5246 343.978 91.362 344.243 91.2635C344.508 91.165 344.794 91.1336 345.074 91.172C345.381 91.2361 345.656 91.4044 345.852 91.6483L345.878 91.6748Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="2.26894"
      />
      <path
        d="M383.642 242.353C383.147 242.353 383.766 244.681 383.801 245.413C383.88 247.116 378.109 248.836 377.27 249.233C377.095 249.489 377.009 249.795 377.025 250.105C377.041 250.415 377.159 250.71 377.359 250.947C377.559 251.184 377.832 251.349 378.135 251.417C378.438 251.485 378.755 251.452 379.037 251.323C380.964 250.759 387.177 248.951 389.28 248.307C389.499 248.246 389.689 248.112 389.82 247.927C389.95 247.742 390.013 247.518 389.996 247.292C389.934 245.66 389.386 240.853 388.768 240.976C387.796 240.95 385.692 242.255 383.642 242.353Z"
        fill="#007459"
      />
      <path
        d="M344.499 246.578C344.022 246.454 343.969 248.845 343.801 249.559C343.412 251.217 337.393 251.323 336.474 251.438C336.237 251.636 336.07 251.906 336 252.207C335.93 252.508 335.96 252.824 336.086 253.106C336.212 253.388 336.427 253.622 336.698 253.772C336.969 253.921 337.281 253.978 337.588 253.934C339.603 253.934 346.063 253.89 348.273 253.855C348.499 253.854 348.718 253.777 348.895 253.636C349.072 253.495 349.195 253.299 349.245 253.079C349.634 251.482 349.864 242.899 349.245 242.855C348.308 242.555 346.496 247.045 344.499 246.578Z"
        fill="#007459"
      />
      <path
        d="M325.764 103.698L327.992 102.815C328.171 102.739 328.364 102.7 328.559 102.701C328.754 102.701 328.946 102.742 329.125 102.819C329.304 102.897 329.465 103.01 329.598 103.152C329.731 103.294 329.834 103.462 329.901 103.645C331.011 106.544 332.319 109.365 333.816 112.086C335.504 115.296 337.498 118.336 339.772 121.163C347.019 124.594 361.275 128.599 361.275 128.599C365.093 130.054 367.258 133.23 366.118 136.158L365.729 137.111C364.589 140.031 360.515 141.239 356.759 139.757L353.515 138.487H353.453C347.214 136.202 341.169 132.895 337.289 129.666C334.037 127.443 330.537 122.557 328.946 118.04L327.868 114.997V114.935C326.649 111.77 325.683 108.514 324.978 105.197C324.922 105.048 324.898 104.888 324.909 104.729C324.919 104.569 324.962 104.414 325.036 104.272C325.11 104.131 325.214 104.007 325.339 103.908C325.465 103.809 325.609 103.737 325.764 103.698Z"
        fill="#007459"
      />
      <path
        d="M363.034 124.179H368.283L367.78 125.802V125.882V125.97L367.665 129.401C367.105 129.682 366.472 129.781 365.853 129.683C365.206 129.567 364.598 129.291 364.085 128.881C363.629 128.525 363.216 128.116 362.857 127.663C362.751 127.531 362.662 127.408 362.592 127.311C362.963 126.393 363.123 125.405 363.06 124.417L363.034 124.179Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="2.26894"
      />
      <path
        d="M361.044 113.356C360.63 112.751 360.377 112.049 360.311 111.319C360.311 110.702 360.541 110.108 360.956 109.652C360.956 109.652 360.072 108.434 361.106 107.817C362.14 107.199 366.25 108.92 366.25 108.92L361.044 113.356Z"
        fill="#072D26"
      />
      <path
        d="M373.833 118.993C373.754 119.452 373.51 119.866 373.147 120.158C372.785 120.45 372.328 120.6 371.862 120.581C371.822 120.567 371.778 120.567 371.738 120.581C371.703 120.601 371.675 120.632 371.659 120.669C369.67 124.365 365.879 126.103 363.051 125.141C359.303 123.862 358.455 118.252 359.887 114.556C361.371 110.675 365.958 107.147 369.529 108.832C371.986 109.987 373.417 113.365 372.869 117.07C372.86 117.111 372.86 117.153 372.869 117.194C372.889 117.234 372.92 117.267 372.958 117.291C373.247 117.458 373.481 117.706 373.629 118.005C373.787 118.309 373.858 118.651 373.833 118.993Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="2.26894"
        strokeMiterlimit="10"
      />
      <path
        d="M362.919 104.147C363.988 103.538 365.57 102.656 367.647 105.832C368.133 106.573 369.414 105.744 371.597 106.379C374.956 107.49 374.337 109.369 376.122 110.595C377.386 111.477 376.829 112.5 377.581 114.961C378.005 116.532 376.158 117.087 376.432 118.578C376.767 120.342 373.78 119.839 373.506 120.898C372.684 123.376 369.635 123.544 368.716 124.285C369.473 123.672 370.172 122.99 370.802 122.247C371.038 121.549 371.381 120.892 371.818 120.298C372.517 120.36 373.48 121.18 373.692 118.534C373.692 116.84 372.649 117.361 372.649 117.361C372.649 117.361 371.916 116.099 372.455 115.297C373.444 113.674 371.067 112.068 368.522 111.636C367.435 111.477 367.47 110.013 366.533 110.013C365.057 109.898 363.97 108.734 362.848 109.131C362.282 109.316 361.814 108.355 361.372 107.94C359.834 106.537 361.036 105.241 362.919 104.147Z"
        fill="#072D26"
      />
      <path
        d="M383.802 245.087C379.666 241.718 363.573 221.589 363.573 221.589C360.515 217.778 360.259 214.664 362.168 213.2L362.804 212.697C364.713 211.171 370.378 211.109 370.378 211.109C377.664 219.958 383.841 229.663 388.769 240.006C388.905 240.273 388.971 240.571 388.96 240.87C388.95 241.169 388.862 241.461 388.707 241.718C387.815 242.926 386.03 245.211 383.802 245.087Z"
        fill="#072D26"
      />
      <path
        d="M343.333 180.81C344.862 174.776 345.684 170.013 349.06 167.402L367.708 170.269C369.476 177.952 362.229 184.109 357.103 197.834C356.219 200.18 353.797 209.962 352.454 215.299C351.111 220.636 346.541 222.091 344.314 221.271C340.682 219.868 340.558 215.934 340.426 215.167C339.701 207.29 341.99 186.084 343.333 180.81Z"
        fill="#072D26"
      />
      <path
        d="M344.154 247.372C340.787 239.812 339.894 217.46 340.531 213.138L352.426 212.379C352.426 219.745 351.348 239.054 350.137 245.466C350.065 245.823 349.897 246.153 349.651 246.422C349.405 246.691 349.09 246.888 348.741 246.992C347.336 247.372 345.047 247.883 344.154 247.372Z"
        fill="#072D26"
      />
      <path
        d="M356.379 171.221C357.59 165.117 378.65 158.872 375.212 184.938C374.452 190.909 371.394 213.455 368.787 217.46C367.771 219.048 362.998 220.883 362.548 220.248C361.751 219.237 361.088 218.128 360.577 216.949C358.288 211.656 358.73 202.535 357.837 196.493C357.077 191.544 354.974 178.269 356.379 171.221Z"
        fill="#072D26"
      />
      <path
        d="M357.013 130.442C358.234 130.159 359.355 129.549 360.256 128.678L360.194 129.693L357.905 134.103L355.926 130.486C356.288 130.534 356.656 130.519 357.013 130.442Z"
        fill="#007459"
      />
      <path
        d="M349.396 138.893C351.923 130.142 360.779 128.308 361.972 128.308C363.974 128.397 365.969 128.607 367.946 128.934C369.461 129.591 370.899 130.414 372.233 131.386C376.165 136.282 372.233 144.617 372.233 150.563C372.233 156.508 371.817 159.533 373.85 163.944C375.388 167.234 375.953 173.118 375.883 173.118C372.445 175.217 361.689 174.935 358.817 174.511C355.016 173.956 346.373 172.553 346.444 170.039C346.727 159.004 347.646 145.05 349.396 138.893Z"
        fill="#007459"
      />
      <path
        d="M316.623 120.925C317.939 117.551 318.053 114.014 317.161 110.801C316.537 108.454 318.697 106.364 320.965 107.153L338.504 112.978C342.815 114.403 347.442 112.087 348.867 107.776L370.952 41.4414C372.377 37.1301 370.061 32.5033 365.75 31.0782L299.856 9.18091C295.545 7.75576 290.918 10.0718 289.493 14.383L283.529 32.3838C282.789 34.6723 284.859 36.8812 287.157 36.2371C290.411 35.2471 294.039 35.2835 297.483 36.5718C305.54 39.5289 309.954 48.3429 307.556 56.5793C304.945 65.5924 295.399 70.505 286.58 67.5715C283.291 66.4644 280.564 64.4396 278.64 61.8876C277.207 59.9492 274.161 60.4491 273.421 62.7376L267.457 80.7384C266.032 85.0496 268.348 89.6765 272.659 91.1016L290.247 96.9478C292.536 97.6879 293.008 100.664 291.097 102.167C288.664 104.084 286.73 106.733 285.685 109.875C282.752 118.694 287.713 128.261 296.726 130.872C304.857 133.109 313.56 128.822 316.623 120.925Z"
        fill="#60CDA9"
      />
      <path
        d="M345.878 91.6748C345.993 91.8294 346.044 92.0221 346.02 92.2129C346.013 92.298 345.992 92.3815 345.958 92.4599C345.911 92.5873 345.855 92.7111 345.79 92.8303L345.596 93.1832L345.419 93.4831L345.366 93.5801L345.286 93.6595C345.068 93.871 344.931 94.1517 344.897 94.4534V94.5416C344.77 95.1058 344.611 95.6625 344.42 96.2087C344.285 96.4837 344.112 96.7389 343.908 96.9673L343.837 97.9905V98.608H339.718L339.577 98.1493L339.453 97.7788C339.163 97.5004 338.889 97.2059 338.631 96.8968C338.173 96.2323 337.829 95.4959 337.615 94.718C337.436 94.2026 337.326 93.6657 337.288 93.1214C337.067 92.0513 336.978 90.9582 337.023 89.8665C337.017 89.8197 337.017 89.7723 337.023 89.7254C337.014 89.6699 337.014 89.6133 337.023 89.5578C337.044 89.4489 337.077 89.3425 337.12 89.2403C337.163 89.1198 337.22 89.0045 337.288 88.8962C337.339 88.822 337.398 88.7539 337.465 88.6934L337.65 88.5699C337.667 88.3648 337.7 88.1614 337.748 87.9612C337.781 87.8093 337.837 87.6635 337.916 87.529C337.976 87.4156 338.06 87.3165 338.162 87.2388C338.264 87.1611 338.382 87.1066 338.508 87.0791C338.525 86.9741 338.551 86.8707 338.587 86.7704C338.65 86.5712 338.765 86.3923 338.92 86.2527C339.076 86.113 339.266 86.0176 339.471 85.9765C339.666 85.9372 339.868 85.9509 340.056 86.016C340.244 86.0812 340.412 86.1955 340.54 86.347C340.612 86.4171 340.678 86.4939 340.735 86.5764C340.949 86.5533 341.165 86.5868 341.362 86.6735C341.559 86.7602 341.73 86.897 341.857 87.0703C342.034 87.2855 342.177 87.5265 342.281 87.7848C342.414 88.1112 342.909 89.549 343.103 90.1929C343.201 90.5193 343.36 91.1985 343.492 91.7983L343.554 91.7366C343.741 91.5246 343.978 91.362 344.243 91.2635C344.508 91.165 344.794 91.1336 345.074 91.172C345.381 91.2361 345.656 91.4044 345.852 91.6483L345.878 91.6748Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="2.26894"
      />
      <path
        d="M338.942 98.4936C338.948 98.163 339.075 97.8459 339.297 97.601C339.52 97.3561 339.824 97.2 340.153 97.1617L342.566 96.7736C342.759 96.7412 342.957 96.7489 343.148 96.7962C343.338 96.8435 343.517 96.9293 343.673 97.0485C343.829 97.1676 343.958 97.3174 344.054 97.4886C344.149 97.6598 344.208 97.8488 344.227 98.0438C344.603 101.11 345.194 104.146 345.995 107.129C346.935 110.64 348.183 114.061 349.724 117.353C355.964 122.372 368.938 129.552 368.938 129.552C372.314 131.836 373.71 135.462 371.872 138.064L371.235 138.946C369.468 141.592 365.252 141.804 361.876 139.511L358.951 137.544L358.889 137.482C353.348 133.795 348.284 129.225 345.208 125.133C342.557 122.213 340.303 116.682 339.729 111.901L339.322 108.726V108.664C339 105.284 338.873 101.888 338.942 98.4936Z"
        fill="#007459"
      />
    </svg>
  )
}
