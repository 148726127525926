import React, { ReactNode } from 'react'
import { FormikSetFieldValue } from '../form.type'
import LabelWrapper from '../LabelWrapper'

import {
  FieldProps as FormikFieldProps,
  FormikHandlers,
  FastField,
} from 'formik'
import wrapStringInArray from '../wrapStringInArray'

interface RenderParams<FormValues, Value> {
  value: Value | null
  errors: React.ReactNode[]
  onChange: FormikHandlers['handleChange']
  onBlur: FormikHandlers['handleBlur']
  setFieldValue: FormikSetFieldValue<FormValues>
}
type RenderInput<FormValues, Value> = (
  params: RenderParams<FormValues, Value>
) => React.ReactNode

interface FieldProps<FormValues, Value> {
  children: RenderInput<FormValues, Value>
  name: string & keyof FormValues
  className?: string
  label: string
  subText?: ReactNode
  placeholder?: string
}

export const formikFieldErrorClass = 'formik-field-error'

/**
 * Facade around Formik Field
 */
const FieldWrapper = <FormValues, Value = string>({
  children,
  className,
  name,
  label,
  subText,
  placeholder,
}: FieldProps<FormValues, Value>) => {
  return (
    <LabelWrapper label={label} className={className}>
      <FastField name={name}>
        {({
          field,
          form: { errors, touched, handleChange, handleBlur, setFieldValue },
        }: FormikFieldProps<Value, FormValues>) => {
          const err: React.ReactNode[] =
            touched[String(name)] && errors[String(name)]
              ? wrapStringInArray(errors[String(name)] as string)
              : []

          return (
            <div className={err?.length > 0 ? formikFieldErrorClass : ''}>
              {/* @ts-ignore suppress use const error */}
              {children({
                value: field.value,
                errors: err,
                onChange: handleChange,
                onBlur: handleBlur,
                setFieldValue,
              } as RenderParams<FormValues, Value>)}
              {subText && (
                <p
                  data-testid="sub-text"
                  className="pt-medium text-m-small-body text-ink-light"
                >
                  {subText}
                </p>
              )}
            </div>
          )
        }}
      </FastField>
    </LabelWrapper>
  )
}

export default FieldWrapper
