import { FullPageLayout } from 'components'
import { useCookieContext } from 'contexts/CookieContext'
import { useAnalytics } from 'hooks'
import { AnalyticsPage, RegistrationFlow } from 'models'
import { useEffect } from 'react'
import {
  ML_REGISTRATION_HOME,
  FULL_MATCH_SUCCESS_ROUTE,
  ML_LOGIN_HOME,
  COOKIE_REG_FLOW_KEY,
} from 'utilities'

export function FullMatchLandingPage() {
  const { page } = useAnalytics()
  const { setCookie } = useCookieContext()
  const analyticsPageName = `${AnalyticsPage.FullMatch}`

  useEffect(() => {
    page(analyticsPageName)
    setCookie(COOKIE_REG_FLOW_KEY, RegistrationFlow.FullMatch)
  }, [])

  return (
    <FullPageLayout
      title="We found an existing profile for you."
      subtitle="It looks like you already have a profile set up with this email address. Log in to connect this account to your Mission Lane profile."
      icon="magnifying-glass"
      showDivider={false}
      redirect={`${ML_LOGIN_HOME}/?redirect=${ML_REGISTRATION_HOME}${FULL_MATCH_SUCCESS_ROUTE}`}
      external={true}
      analyticsPage={analyticsPageName}
      buttonText="Log In"
      bgColor="bg-white"
    />
  )
}
