import React, { HTMLAttributes } from 'react'
import './Icon.css'

/** only support styles loaded by our kit, use the array format that @fortawesome/react-fontawesome for future migration ease **/
export type IconRef = ['far' | 'fab' | 'fas', string]
export interface IconProps extends HTMLAttributes<HTMLElement> {
  /** The icon to render, loaded from `Icons` or directly from a fortawesome package */
  icon: IconRef
  /** T-shirt sizes will square the icon with some padding per the Compass design. `inherit` will not add any padding and keep the icon at the current `font-size` (good for inline icons.) Defaults to `medium` */
  size?: 'small' | 'medium' | 'large' | 'inherit'

  className?: string
}
/**
 * If a picture is worth a thousand words, an icon must be worth at least a dozen. Or maybe just one. Either way, we use ‘em to help illustrate, indicate, and otherwise support our design and content.
 *
 * This is a thin wrapper around `FontAwesomeIcon` which enforces Compass sizing and framing, and uses SVG icons for better scaling, transforms, and smaller downloads. There are some exceptions to the framing, which adds slight padding and makes icons squared. You can override this in CSS as needed. Icons will be the same color as text by default.
 *
 * [Figma](https://www.figma.com/file/wVz0YgLZIVsh9WJAFZSlZf/Compass-%7C-Design-System?node-id=3701%3A11704)
 *
 * @example {<Icon icon={['far', fa-times']} className='my-icon'/>}
 */
export const Icon: React.FC<IconProps> = ({
  icon,
  size = 'medium',
  className,
  ...props
}) => {
  return (
    <i
      role="img"
      aria-label={icon[1]}
      {...props}
      className={`Icon ${icon[0] ?? ''} fa-${icon[1] ?? ''} ${size ?? ''} ${
        className ?? ''
      }`}
    />
  )
}

export default Icon
