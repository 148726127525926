import { COOKIE_EMAIL_DISPLAY_KEY } from 'utilities'
import { useCookieContext } from 'contexts/CookieContext'
import { UserIcon } from './icons'

interface Props {
  className: string
  containerClassName?: string
  label?: string
}

export function UsernameContainer({
  className,
  containerClassName = '',
  label = '',
}: Props) {
  const { getCookie } = useCookieContext()
  const username = getCookie(COOKIE_EMAIL_DISPLAY_KEY)
  return (
    <div className={`${containerClassName}`}>
      {label && <label className="font-semibold">{label}</label>}
      <div className={`banner ${className}`}>
        <UserIcon className="pr-4" /> {username}
      </div>
    </div>
  )
}
