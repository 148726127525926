import React from 'react'

interface LabelWrapperProps {
  className?: string
  label: string | JSX.Element
  children: React.ReactNode
}

const LabelWrapper = ({ children, className, label }: LabelWrapperProps) => (
  <div className={`${className ?? ''}`}>
    <label className="font-semibold">{label}</label>
    {children}
  </div>
)

export default LabelWrapper
