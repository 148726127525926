import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import { getEnvValue } from 'utilities'

/**
 * Attempts to init Bugsnag, returning an ErrorBoundary if successful that will catch
 * unhandled render errors.
 */
export function bugsnagInit(): BugsnagErrorBoundary | typeof React.Fragment {
  const apiKey = getEnvValue('REACT_APP_BUGSNAG_KEY')
  const appVersion = getEnvValue('REACT_APP_VERSION')
  const releaseStage = getEnvValue('REACT_APP_ENV')
  if (apiKey) {
    Bugsnag.start({
      apiKey,
      appVersion,
      releaseStage,
      plugins: [new BugsnagPluginReact()],
      enabledReleaseStages: ['production', 'staging', 'development'],
      enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request'],
    })
  }
  const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)
  return ErrorBoundary ?? React.Fragment
}

export interface FallbackComponentProps {
  error: Error
  info: React.ErrorInfo
  clearError: () => void
}
