import {
  FMCtaContent,
  FmTitleContent,
  Header,
  UsernameContainer,
} from 'components'
import { MagnifyingGlassIcon, ApplicationIcon, ConfettiIcon } from '../icons'
import useBreakpoint from 'hooks/useBreakpoint'
import { FullPageIcons } from 'models'

interface Props {
  title?: string
  subtitle?: string
  isSuccess?: boolean
  icon?: FullPageIcons
  showEmail?: boolean
  bgColor?: string
  showDivider?: boolean
  redirect?: string
  external?: boolean
  analyticsPage: string
  buttonText?: string
  buttonClass?: string
}

export const getIconFromName = (iconName: FullPageIcons) => {
  switch (iconName) {
    case 'magnifying-glass':
      return <MagnifyingGlassIcon />
    case 'application':
      return <ApplicationIcon />
    case 'confetti':
      return <ConfettiIcon className="max-h-[250px]" />
    default:
      return <MagnifyingGlassIcon />
  }
}

export function FullPageLayout({
  title = '',
  subtitle = '',
  isSuccess = false,
  icon,
  showEmail = false,
  bgColor = 'bg-teal-wash',
  redirect = '',
  external = false,
  analyticsPage,
  buttonText = 'Go to Dashboard',
  buttonClass = '',
}: Props) {
  const { isTablet } = useBreakpoint()

  return (
    <section
      className={`grid-flow-cols h-screen grid-cols-1 overflow-scroll ${bgColor} lg:grid-rows-2`}
    >
      <Header bgColor={bgColor} analyticsPage={analyticsPage}></Header>
      <div
        className={`${
          isTablet ? 'mx-24' : 'mx-8 max-w-screen-formContainer lg:mx-auto'
        }`}
      >
        <div className="px-auto flex justify-center py-2">
          {getIconFromName(icon)}
        </div>
        <FmTitleContent
          subtitle={
            showEmail
              ? subtitle + ' An email confirmation has been sent to:'
              : subtitle
          }
          title={title}
          isSuccess={isSuccess}
          subtitleClassName="text-ink-light pt-8"
        />
        {showEmail && <UsernameContainer className="mt-6 bg-white" />}
        <FMCtaContent
          className="pb-4"
          buttonText={buttonText}
          buttonClass={buttonClass}
          redirect={redirect}
          external={external}
          analyticsPage={analyticsPage}
        />
      </div>
    </section>
  )
}
