import { BottomSheetButton } from './icons'
import { PrimaryActionButton } from './PrimaryActionButton'

interface Props {
  className?: string
  onClose: () => void
  title: string
  primaryButtonText: string
  secondaryButtonText: string
  subtitleMarkup: React.ReactNode
  onClick: any
  isSuccess: boolean
  isActing: boolean
}

export function BottomSheet({
  className = '',
  onClose,
  title,
  primaryButtonText,
  secondaryButtonText,
  subtitleMarkup,
  onClick,
  isSuccess,
  isActing,
}: Props) {
  return (
    <>
      <div className={`bottomSheet absolute z-20 px-6 pt-small ${className}`}>
        <div>
          <div className="flex flex-col justify-between">
            <BottomSheetButton className="mx-auto mb-4" />
            <h3 className="m-h1 pb-4 text-ink">{title}</h3>
          </div>
          {subtitleMarkup}
        </div>
        <div className="grid-cols grid items-center pt-6">
          <PrimaryActionButton
            className="btn px-auto mt-0"
            defaultText={primaryButtonText}
            isSuccess={isSuccess}
            onCancel={onClose}
            onClick={onClick}
            actingText="Loading..."
            successText="Success!"
            isActing={isActing}
          />
          <PrimaryActionButton
            className="btn-outline my-6"
            defaultText={secondaryButtonText}
            isSuccess={isSuccess}
            onCancel={onClose}
            onClick={onClose}
            actingText="Loading..."
            successText="Success!"
            isActing={false}
          />
        </div>
      </div>
    </>
  )
}
