interface Props {
  children: React.ReactNode
}

export function Layout({ children }: Props) {
  return (
    <main id="main" role="main">
      {children}
    </main>
  )
}
