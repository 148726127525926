import Cookies from 'js-cookie'
import { CookieConfig } from 'models'
import { createContext, useContext } from 'react'
import {
  COOKIE_ATTRIBUTES,
  COOKIE_REG_FLOW_KEY,
  COOKIE_REG_TOKEN_KEY,
} from 'utilities'

const CookieContext = createContext<CookieConfig>(getInitialValue())
CookieContext.displayName = 'Cookie'

export const useCookieContext = () => useContext(CookieContext)

function getCookie(key: string) {
  return Cookies.get(key) ?? ''
}

function setCookie(key: string, value: string) {
  Cookies.set(key, value, COOKIE_ATTRIBUTES)
}

function removeCookie(key: string) {
  Cookies.remove(key, COOKIE_ATTRIBUTES)
}

/**
 * Remove the cookies associated the registration context
 * when a user has completed registration and will be redirected
 * to the product application
 */
function clearRegCookies() {
  removeCookie(COOKIE_REG_TOKEN_KEY)
  removeCookie(COOKIE_REG_FLOW_KEY)
}

function getInitialValue(): CookieConfig {
  return {
    getCookie,
    setCookie,
    removeCookie,
    clearRegCookies,
  }
}
