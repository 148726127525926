interface Props {
  className?: string
}

export function Line({ className = '' }: Props) {
  return (
    <div
      className={`h-0 self-center border-b border-grey-lighter ${className}`}
    ></div>
  )
}
