import { Line } from './Line'
import { VerticalLine } from './VerticalLine'

interface Props {
  text?: string
  className?: string
  isVertical?: boolean
}

export function Divider({
  text = '',
  className = '',
  isVertical = false,
}: Props) {
  return (
    <div
      className={`${
        isVertical ? `verticalDivider` : `horizontalDivider`
      } ${className}`}
    >
      {isVertical ? <VerticalLine /> : <Line />}
      <span className={isVertical ? `py-2` : `mx-extra-large`}>{text}</span>
      {isVertical ? <VerticalLine /> : <Line />}
    </div>
  )
}
