import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Layout } from 'components'
import { AnalyticsProvider } from 'contexts/AnalyticsContext'
import { RegistrationContextProvider } from 'contexts/RegistrationContext'
import './App.scss'
import { UrlRoutes } from './routes/Routes'
import * as FullStory from '@fullstory/browser'
import { getEnvValue } from 'utilities'

FullStory.init({
  orgId: getEnvValue('REACT_APP_FULLSTORY_ORG_ID'),
  devMode: getEnvValue('REACT_APP_FULLSTORY_PROD_LOGGING') !== 'true',
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // do not refetch anytime a user refocuses on the window
      retry: 1,
    },
  },
})

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AnalyticsProvider>
        <RegistrationContextProvider>
          <Layout>
            <UrlRoutes />
          </Layout>
        </RegistrationContextProvider>
      </AnalyticsProvider>
    </QueryClientProvider>
  )
}
