import { BottomSheet } from 'components/BottomSheet'
import { useAnalytics } from 'hooks'
import { AnalyticsEvent, AnalyticsPage } from 'models'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  completeRegistrations,
  CREATE_PASSWORD_ROUTE,
  ERROR_ROUTE,
} from 'utilities'

interface Props {
  open: boolean
  onClose: () => void
  className?: string
}

export function EmailDeconflictBottomSheet({
  open,
  onClose,
  className = '',
}: Props) {
  const title = 'Create a new profile?'
  const primaryButtonText = 'Yes, Continue'
  const secondaryButtonText = 'Back'

  const { trackClickEvent, trackEvent } = useAnalytics()
  const [triggerComplete, setTriggerComplete] = useState<boolean>(false)
  const {
    data: completeRegistrationData,
    isLoading,
    error,
  } = completeRegistrations({ enabled: triggerComplete })
  const navigate = useNavigate()
  useEffect(() => {
    if (completeRegistrationData?.registrationComplete) {
      navigate(CREATE_PASSWORD_ROUTE)
    }
    if (error) {
      navigate(ERROR_ROUTE)
      trackEvent(
        `${AnalyticsPage.CreateNewProfileBottomSheet}: ${AnalyticsEvent.Error}`,
        {
          event: AnalyticsEvent.ErrorMessage,
        }
      )
    }
  }, [completeRegistrationData])

  if (!open) {
    className = ''
  }
  const continueToSetPassword = () => {
    trackClickEvent({
      event: `${AnalyticsPage.CreateNewProfileBottomSheet}: ${AnalyticsEvent.ContinueToSetPassword}`,
    })
    setTriggerComplete(true)
  }

  return (
    <BottomSheet
      onClose={onClose}
      title={title}
      subtitleMarkup={
        <p className="text-standard text-ink">
          Continuing with a new email means{' '}
          <strong>
            this account will be separate from your existing profile.
          </strong>{' '}
          Do you want to continue with a new email?
        </p>
      }
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      onClick={continueToSetPassword}
      isSuccess={completeRegistrationData?.registrationComplete}
      isActing={triggerComplete && isLoading}
      className={`${className} bottom-0`}
    />
  )
}
