import React, { InputHTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import Errors from './Errors'

import {
  ERROR_BORDER_STYLE,
  NORMAL_BORDER_STYLE,
  PSEUDO_FOCUS_BORDER_COLOR,
  ERROR_BACKGROUND_COLOR,
  NORMAL_BACKGROUND_COLOR,
} from './InputStyles'
import SnapshotInput from './Formik/fields/SnapshotInput'

export const errorBackgroundStyles = {
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 8px center',
}

/**
 * Returns style classes for an input component.
 * @param hasErrors
 */
export const getInputStyles = (
  hasErrors: boolean,
  type: string = '',
  showValidCheck = false,
  showErrorIcon = false
) => {
  const baseInputClasses =
    'input-reset book ba bw1 db w-100 br2 tracked outline-0'

  const borderClasses = `${
    hasErrors ? ERROR_BORDER_STYLE : NORMAL_BORDER_STYLE
  } ${PSEUDO_FOCUS_BORDER_COLOR}`

  const backgroundColorClasses = hasErrors
    ? ERROR_BACKGROUND_COLOR
    : NORMAL_BACKGROUND_COLOR

  const pwErrorClass =
    hasErrors && (type === 'password' || showErrorIcon) ? 'input-error' : ''
  const validClass = showValidCheck ? 'input-success' : ''

  return `${baseInputClasses} ${borderClasses} ${backgroundColorClasses} ${pwErrorClass} ${validClass}`
}

export interface InputProps<T = HTMLInputElement>
  extends InputHTMLAttributes<T> {
  errors: React.ReactNode[]
  postfixText?: string
  inputRef?: React.LegacyRef<HTMLInputElement>
  errorClassName?: string
  showValidCheck?: boolean
  showErrorIcon?: boolean
}

const Input = (props: InputProps) => {
  const {
    errorClassName = '',
    className,
    errors,
    inputRef,
    style,
    postfixText,
    showValidCheck = false,
    showErrorIcon = false,
    ...inputProps
  } = props

  const hasErrors = errors.length !== 0
  const defaultStyle = (hasErrors && errorBackgroundStyles) || {}
  return (
    <Errors errors={errors} className={errorClassName}>
      <div className="relative">
        <SnapshotInput
          {...inputProps}
          inputRef={inputRef}
          className={`${getInputStyles(
            hasErrors,
            inputProps.type,
            showValidCheck,
            showErrorIcon
          )} ${className ?? ''}`}
          style={{ ...defaultStyle, ...style }}
        />
        {postfixText && (
          <p className="absolute top-50 translate-y-50">{postfixText}</p>
        )}
      </div>
    </Errors>
  )
}

Input.defaultProps = {
  id: null,
  type: 'text',
  errors: [],
  inputRef: null,
  className: '',
  errorClassName: '',
  onInput: () => {},
  onPaste: () => {},
  onChange: () => {},
  onKeyPress: () => {},
  onBlur: () => {},
  onFocus: () => {},
  placeholder: '',
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onPaste: PropTypes.func,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  onInput: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
}

export default Input
