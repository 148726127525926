import { LayoutContainer } from 'components'
import { useAnalytics } from 'hooks'
import { AnalyticsPage } from 'models'
import { useEffect } from 'react'

export function PageNotFound() {
  const { page } = useAnalytics()
  const analyticsPageName = `${AnalyticsPage.Registration} ${AnalyticsPage.PageNotFound}`
  useEffect(() => {
    page(analyticsPageName)
  }, [])
  return (
    <LayoutContainer analyticsPage={analyticsPageName}>
      <p id="loading h-100">Page Not Found</p>
    </LayoutContainer>
  )
}
