import { AnalyticsEvent, AnalyticsPage } from 'models'
import { ML_HELP_LINK } from 'utilities'
import { TextAndLink } from './TextAndLink'

interface Props {
  subtitle: string
  title: string
  isSuccess: boolean
  className?: string
  subtitleLink?: boolean
  subtitleClassName?: string
}

export function FmTitleContent({
  subtitle,
  subtitleLink = false,
  title,
  isSuccess,
  className = '',
  subtitleClassName = '',
}: Props) {
  return (
    <div className={className}>
      <h2 className={`success text-center ${isSuccess ? `` : `hidden`}`}>
        Congrats!
      </h2>
      {title && <h2 className="text-center">{title}</h2>}
      {subtitle && !subtitleLink && (
        <p className={`text-center text-long-form ${subtitleClassName}`}>
          {subtitle}
        </p>
      )}
      {subtitleLink && (
        <TextAndLink
          text={subtitle}
          external
          link={ML_HELP_LINK}
          className="pt-12 text-center text-long-form"
          linkText="get help."
          analyticsEventName={`${AnalyticsPage.FullMatch}: ${AnalyticsEvent.Help}`}
        />
      )}
    </div>
  )
}
