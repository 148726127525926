import { AuthError } from 'components/DefaultAuthError'
import { LeftUIContainer, PrimaryActionButton } from 'components'
import { TextField } from 'components/Form/Formik'
import { Formik, Form } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  navigateToMissionLaneHome,
  PASSWORD_VALIDATION_MSG,
  PASSWORD_VALIDATION_SCHEMA,
  createNewPassword,
  NO_MATCH_SUCCESS_ROUTE,
  notifyBugsnag,
  COOKIE_REG_FLOW_KEY,
} from 'utilities'
import { useCreatePasswordContext } from 'contexts'
import { RegistrationFlowHeader } from '../RegistrationFlowHeader'
import { TitleContent } from 'components/TitleContent'
import { CtaContent } from 'components/CtaContent'
import { useAnalytics } from 'hooks'
import { AnalyticsEvent, AnalyticsPage } from 'models'
import { useCookieContext } from 'contexts/CookieContext'

export function CreateNewPassword() {
  const { title, subtitle, isPasswordCreated } = useCreatePasswordContext()
  const { getCookie } = useCookieContext()
  const registrationFlow = getCookie(COOKIE_REG_FLOW_KEY)
  const { page, trackClickEvent, trackEvent, trackInputEvent } = useAnalytics()
  const analyticsPageName = `${AnalyticsPage.RegistrationPassword}: ${registrationFlow}`
  const navigate = useNavigate()
  const [password, setPassword] = useState<string>('')
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    createPasswordMutation.reset()
    trackInputEvent({
      event: `${AnalyticsPage.RegistrationPassword}: ${AnalyticsEvent.EnterPassword}`,
    })
    setPassword(event.currentTarget.value)
    setIsPasswordValid(!!event.currentTarget.value)
  }
  const handleConfirmPasswordChange = (
    _: React.ChangeEvent<HTMLInputElement>
  ) => {
    createPasswordMutation.reset()
    trackInputEvent({
      event: `${AnalyticsPage.RegistrationPassword}: ${AnalyticsEvent.ConfirmPassword}`,
    })
  }
  const createPasswordMutation = createNewPassword()

  const submitPassword = () => {
    trackClickEvent({
      event: `${AnalyticsPage.RegistrationPassword}: ${AnalyticsEvent.SubmitPassword}`,
    })
    const body = JSON.stringify({ password })
    createPasswordMutation.mutate(body)
  }

  useEffect(() => {
    if (createPasswordMutation.isSuccess) {
      navigate(NO_MATCH_SUCCESS_ROUTE)
    }
    if (createPasswordMutation.error) {
      trackEvent(
        `${AnalyticsPage.RegistrationPassword}: ${AnalyticsEvent.Error}`,
        {
          event: AnalyticsEvent.ErrorMessage,
        }
      )
      notifyBugsnag({
        error: createPasswordMutation.error as Error,
        name: 'CreatePassword',
      })
    }
  }, [createPasswordMutation.isSuccess, createPasswordMutation.error])

  useEffect(() => {
    page(analyticsPageName)
  }, [])

  return (
    <section className="grid h-screen grid-flow-row grid-cols-4 grid-rows-[min-content_1fr] bg-white lg:auto-rows-fr lg:grid-cols-12 lg:grid-rows-none">
      <LeftUIContainer analyticsPage={analyticsPageName} />
      <div className="lg:form-max-width col-span-8 justify-items-center lg:my-even-larger">
        <RegistrationFlowHeader
          isSuccessPage={isPasswordCreated}
          title={title}
          analyticsPageName={analyticsPageName}
        />
        <div className="form-max-width px-6 lg:px-0">
          <TitleContent
            isSuccessPage={isPasswordCreated}
            subtitle={subtitle}
            title={title}
          />
          <Formik
            validationSchema={PASSWORD_VALIDATION_SCHEMA}
            onSubmit={submitPassword}
            initialValues={{
              password,
              confirmPassword: '',
            }}
          >
            {({ isSubmitting, status, isValid, values, touched }) => (
              <Form>
                <>
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    inputProps={{
                      autoFocus: true,
                      onChange: handlePasswordChange,
                    }}
                    subText={PASSWORD_VALIDATION_MSG}
                    fieldClassName="pb-larger"
                    errorClassName="float-none mt-4"
                    shouldShowValidCheck={touched.password}
                  />
                  {isPasswordValid && (
                    <TextField
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      inputProps={{
                        onChange: handleConfirmPasswordChange,
                      }}
                      shouldShowValidCheck={touched.confirmPassword}
                      errorClassName="mt-4"
                    />
                  )}
                  {!isPasswordValid && (
                    <TextField
                      id="confirmPasswordDisabled"
                      name="confirmPasswordDisabled"
                      label="Confirm Password"
                      type="password"
                      inputProps={{
                        disabled: true,
                      }}
                      errorClassName="mt-4"
                    />
                  )}
                  {createPasswordMutation.error && (
                    <AuthError
                      className="col-span-4 row-start-3 mt-8"
                      errors={(createPasswordMutation.error as Error).message}
                    />
                  )}
                  <PrimaryActionButton
                    defaultText="Set My Password"
                    isSuccess={status === 'success' && !isSubmitting}
                    disabled={!isValid || !values.confirmPassword}
                    onCancel={navigateToMissionLaneHome}
                    type="submit"
                    actingText="Submitting..."
                    successText="Success!"
                    isActing={createPasswordMutation.isLoading}
                  />
                </>
              </Form>
            )}
          </Formik>
          {isPasswordCreated && <CtaContent />}
        </div>
      </div>
    </section>
  )
}
