export interface TrackEventMetadata {
  [key: string]: any
}

export enum AnalyticsPage {
  Complete = 'Complete',
  CreateNewProfileBottomSheet = 'Create New Profile Bottom Sheet',
  CreateNewProfileModal = 'Create New Profile Modal',
  EmailOnlyMatch = 'Email Already In Use',
  Error = 'Generic Error Page',
  ExistingEmailAddressFound = 'Existing Email Address Found',
  FullMatch = 'Full Registration Match',
  Landing = 'Landing',
  Loading = 'Loading',
  Maintenance = 'Under Maintenance Error',
  NoMatch = 'No Match',
  PageNotFound = 'Page Not Found',
  Registration = 'Registration',
  RegistrationPassword = 'Registration Password',
  Success = 'Success',
  Timeout = 'Time Out',
}

export enum AnalyticsEvent {
  Api = 'API Request',
  Back = 'Back',
  ChangeEmail = 'Change Email',
  Click = 'Click',
  ConfirmPassword = 'Confirm Password',
  ContinueToSetPassword = 'Continue to Set Password',
  Dashboard = 'To Dashboard',
  EnterPassword = 'Enter Password',
  Error = 'Error',
  ErrorMessage = 'ErrorMessage',
  ErrorHeader = 'Error Header',
  Help = 'Get Help',
  Input = 'Input',
  Login = 'Log In',
  PageViewed = 'Page Viewed',
  RedirectToError = 'Redirect to Error page',
  Submit = 'Submit',
  SubmitPassword = 'Submit Password',
}

export enum AnalyticsError {
  Auth = 'Invalid Auth Token',
}

/**
 * This enum is used to log analytics events on various
 * pages by name given the user is in a specific registration
 * flow scenario
 */
export enum RegistrationFlow {
  EmailOnlyMatch = 'Email Match Only New Profile',
  FullMatch = 'Full Registration Match',
  NoMatch = 'No Match',
  SsnDobMatch = 'SSN DOB Match Existing Profile',
  Unset = '',
}
