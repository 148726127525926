import { Resolution } from './get-registration-response.interface'

export type OTPFactor = 'sms' | 'call'
export enum SessionType {
  fullMatch = 'fullMatch',
  noMatch = 'noMatch',
}

export interface RegistrationConfig {
  factorType?: OTPFactor
  sessionType?: SessionType
  setEmail: React.Dispatch<React.SetStateAction<string>>
  email: string
  productCode?: string
  setProductCode: React.Dispatch<React.SetStateAction<string>>
  productRedirectUrl?: string
  setRedirectUrl: React.Dispatch<React.SetStateAction<string>>
  resolution: Resolution
  setResolution: React.Dispatch<React.SetStateAction<Resolution>>
}
