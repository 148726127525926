import { useEffect, useState } from 'react'

interface Props {
  message?: string
  width: number
}

export function LoadingProgressIndicator({
  message = 'Loading...',
  width,
}: Props) {
  const [value, setValue] = useState(0)
  useEffect(() => {
    setValue(width)
  })
  return (
    <>
      <h1 className="mx-auto mb-16 text-m-h2 lg:text-h3">{message}</h1>
      <div className="progress-container" style={{ width }}>
        <div style={{ width: `${value}px` }} className="progress-line" />
      </div>
    </>
  )
}
