import { AnalyticsEvent } from 'models'
import { ML_HELP_LINK } from 'utilities'
import { ExternalLink } from './ExternalLink'
import { HelpIcon, MLTextMobileIcon } from './icons'

export function MobileHeader() {
  return (
    <div className="grid grid-flow-col grid-cols-[60px_1fr_min-content] bg-green lg:hidden">
      <MLTextMobileIcon className="col-start-2 mx-auto my-4 justify-self-center fill-white lg:hidden" />
      <ExternalLink
        to={ML_HELP_LINK}
        className="col-start-3 justify-self-end"
        title="Get help"
        newTab
        analyticsEventName={`Mobile Breakpoint Header: ${AnalyticsEvent.Help}`}
      >
        <HelpIcon className="mr-8 h-full fill-white lg:hidden" />
      </ExternalLink>
    </div>
  )
}
