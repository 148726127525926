import useReactMutation from 'hooks/useReactMutation'
import useReactQuery from 'hooks/useReactQuery'
import {
  AuthCompleteRegistrationResponse,
  GetRegistrationResponse,
} from 'models'
import { CompleteRegistrationResponse } from 'models/complete-registration-response'

import {
  REG_API_SET_PASSWORD_ENDPOINT,
  REG_API_GET_ENDPOINT,
  REG_API_GET_REGISTRATIONS_KEY,
  REG_API_AUTH_COMPLETE_ENDPOINT,
  REG_API_AUTH_COMPLETE_REGISTRATIONS_KEY,
  getEnvValue,
  REG_API_COMPLETE_REGISTRATIONS_KEY,
  REG_API_COMPLETE_ENDPOINT,
} from 'utilities'

/**
 * Fetch the registration data
 */
export function getRegistrations() {
  return useReactQuery<GetRegistrationResponse>({
    key: REG_API_GET_REGISTRATIONS_KEY,
    url: `${getEnvValue(
      'REACT_APP_ML_REG_API_BASE_URL'
    )}/${REG_API_GET_ENDPOINT}`,
    method: 'POST',
    enabled: true,
  })
}

/**
 * Set password
 */
export function createNewPassword() {
  return useReactMutation({
    url: `${getEnvValue(
      'REACT_APP_ML_REG_API_BASE_URL'
    )}/${REG_API_SET_PASSWORD_ENDPOINT}`,
  })
}

/**
 * Complete registration (Authenticated)
 */
export function authCompleteRegistrations(enabled?: boolean) {
  return useReactQuery<AuthCompleteRegistrationResponse>({
    key: REG_API_AUTH_COMPLETE_REGISTRATIONS_KEY,
    url: `${getEnvValue(
      'REACT_APP_ML_REG_API_BASE_URL'
    )}/${REG_API_AUTH_COMPLETE_ENDPOINT}`,
    method: 'POST',
    role: 'customer',
    enabled,
  })
}

/**
 * Complete registration
 */
export function completeRegistrations({
  enabled = false,
}: {
  enabled?: boolean
  email?: string
}) {
  return useReactQuery<CompleteRegistrationResponse>({
    key: REG_API_COMPLETE_REGISTRATIONS_KEY,
    url: `${getEnvValue(
      'REACT_APP_ML_REG_API_BASE_URL'
    )}/${REG_API_COMPLETE_ENDPOINT}`,
    method: 'POST',
    role: 'system',
    enabled,
  })
}

/**
 * Complete registration with an updated email
 */
export function completeRegistrationsWithEmail() {
  return useReactMutation({
    url: `${getEnvValue(
      'REACT_APP_ML_REG_API_BASE_URL'
    )}/${REG_API_COMPLETE_ENDPOINT}`,
    role: 'system',
  })
}
