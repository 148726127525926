import { Back, TextAndLink } from 'components'
import { useRegistrationContext } from 'contexts'
import { useAnalytics } from 'hooks'
import useBreakpoint from 'hooks/useBreakpoint'
import { AnalyticsEvent } from 'models'
import { ML_HELP_LINK } from 'utilities'

interface Props {
  isSuccessPage: boolean
  title: string
  analyticsPageName: string
}

export function RegistrationFlowHeader({
  isSuccessPage,
  title,
  analyticsPageName,
}: Props) {
  const { trackClickEvent } = useAnalytics()
  const { isDesktop, isPhone, isTablet } = useBreakpoint()
  const { resolution } = useRegistrationContext()
  const removeBackButton =
    resolution === 'EMAIL_DECONFLICT_OPTIONAL' || 'EMAIL_DECONFLICT_REQUIRED'
  const goBack = () => {
    trackClickEvent({ event: `${analyticsPageName}: ${AnalyticsEvent.Back}` })
    history.back()
  }
  return (
    <div
      className={`mb-7 grid w-full ${
        removeBackButton ? 'grid-cols-[1fr]' : 'grid-cols-[min-content_1fr]'
      }  items-center py-7 px-6 lg:px-0 ${
        isPhone || isTablet ? 'bg-ink-wash' : ''
      } ${isSuccessPage ? 'hidden' : ''}`}
    >
      <Back
        className={`justify-self-start ${removeBackButton ? 'hidden' : ''}`}
        onClick={goBack}
      />

      {!isDesktop || isSuccessPage ? (
        <div className="justify-self-start pl-5 text-m-h4 md:text-m-h2">
          {title}
        </div>
      ) : (
        <TextAndLink
          text={`Having trouble?${' '}`}
          external
          link={ML_HELP_LINK}
          className="justify-self-end"
          linkText="Get help"
          analyticsEventName={`${analyticsPageName}: ${AnalyticsEvent.Help}`}
        />
      )}
    </div>
  )
}
