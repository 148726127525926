import {
  ErrorEmbarrassingIcon,
  FmTitleContent,
  Header,
  WingedHourglassIcon,
} from 'components'
import { useAnalytics } from 'hooks'
import { AnalyticsPage } from 'models'
import { useEffect, useMemo } from 'react'

interface Props {
  isTimeout?: boolean
}

export function ErrorPage({ isTimeout = false }: Props) {
  const { page } = useAnalytics()
  const analyticsPageName = `${AnalyticsPage.Registration} ${
    isTimeout ? AnalyticsPage.Timeout : AnalyticsPage.Error
  }`
  useEffect(() => {
    page(analyticsPageName)
  }, [])
  const [title, subtitle] = useMemo(() => {
    return isTimeout
      ? [
          'Your session has timed out due to inactivity.',
          "For your security, we'll send you an email with instructions on how to complete your registration shortly.",
        ]
      : [
          'Uh oh... Something went wrong.',
          "It's not you, it's us. We will send you a link to complete your registration later. If the issue persists, feel free to send us an email or call our customer service.",
        ]
  }, [isTimeout])
  return (
    <section
      className={`grid-flow-cols h-screen grid-cols-1 overflow-scroll bg-haze-lightest lg:grid-rows-2`}
    >
      <Header bgColor="white" analyticsPage={analyticsPageName} />
      <div className="mx-8 max-w-screen-formContainer md:mx-auto">
        <div className="mx-auto flex justify-center py-6 md:pt-4">
          {isTimeout ? (
            <WingedHourglassIcon className="mx-8" />
          ) : (
            <ErrorEmbarrassingIcon className="mx-8" />
          )}
        </div>
        <FmTitleContent
          subtitle={subtitle}
          title={title}
          isSuccess={false}
          subtitleClassName="text-grey"
        />
      </div>
    </section>
  )
}
