import * as Yup from 'yup'

const requiredMessage = 'This field is required.'

export const EMAIL_VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string()
    .matches(/^(\S+$)/, 'Enter your email without spaces.')
    .email('Invalid email, need help?')
    .nullable()
    .required(requiredMessage),
})

/**
 * Enforces the following:
 *   email does not have spaces
 *   confirmEmail matches email value
 */
export const EMAIL_MATCH_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .matches(/^(\S+$)/, 'Enter your email without spaces.')
    .email('Invalid email address.')
    .nullable()
    .required(requiredMessage),
  confirmEmail: Yup.string().equals(
    [Yup.ref('email')],
    'Email does not match.'
  ),
})

export const REQUIRED_VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string().nullable().required(requiredMessage),
})

export const PASSWORD_VALIDATION_MSG =
  "Enter a new password that's at least 8 characters long and contains at least one uppercase letter, one lowercase letter, one number, and one special character (like !, @, #, $, %, ^, &, *)."

/**
 * Enforces the following:
 *   at least 8 characters long
 *   doesn't include parts of your username
 *   contains at least 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character (like !, @, #, $, %, ^, &, *, .)
 */
export const PASSWORD_VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,24}$/,
      "This password doesn't meet the requirements. Please try again."
    )
    .nullable()
    .required(requiredMessage),
  confirmPassword: Yup.string().equals(
    [Yup.ref('password')],
    "These passwords don't match. Please try again."
  ),
})
