import { ExternalLink } from 'components'
import { useAnalytics } from 'hooks'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface LinkOnClickProps extends LinkBaseProps {
  to?: undefined
  onClick: (e: React.MouseEvent) => void
  external?: undefined
}

interface LinkToProps extends LinkBaseProps {
  to: string
  onClick?: undefined
  external?: boolean
}

interface LinkBaseProps {
  onClick?: (e: React.MouseEvent) => void
  children: ReactNode
  to?: string
  external?: boolean
  className?: string
  analyticsEventName: string
}

// Link can either have "to" or "onClick", but not both
type LinkProps = LinkToProps | LinkOnClickProps

export function RegistrationLink({
  onClick,
  to,
  children,
  external,
  className,
  analyticsEventName,
}: LinkProps) {
  function handleMouseDown(e: React.MouseEvent<HTMLAnchorElement>) {
    const { trackClickEvent } = useAnalytics()
    e.preventDefault()
    if (analyticsEventName)
      trackClickEvent({
        event: analyticsEventName,
      })
    onClick?.(e)
  }

  if (!!external && !!to) {
    return (
      <ExternalLink
        to={to}
        analyticsEventName={analyticsEventName}
        newTab={external}
      >
        {children}
      </ExternalLink>
    )
  }

  return (
    <Link className={className} onMouseDown={handleMouseDown} to={to ?? '#'}>
      {children}
    </Link>
  )
}
