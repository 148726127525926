import React, { ReactElement } from 'react'
import Button, { ButtonProps } from './Button'
import Icon from '../icons/Icon'

export interface ActionButtonProps extends Omit<ButtonProps, 'children'> {
  /** The content of the Button. If `actingText` or `successText` are not provided, children will be used in those states. */
  children?: React.ReactNode
  /** True when the button should be in an acting state */
  isActing?: boolean
  /** The content of the Button when in an acting state. */
  actingText?: React.ReactNode
  /** True when the button should be in a success state */
  isSuccess?: boolean
  /** The content of the button when in a success state. Typo kept for backwards compatability */
  successText?: React.ReactNode

  /**
   * **DEPRECATED**. Use `children`. The content of the Button. If `actingText` or `successText` are not provided, `defaultText` will be used in those states.
   * @deprecated use children
   * */
  defaultText?: string
}

const actingIcon = (
  <Icon
    icon={['far', 'spinner-third']}
    className="acting-icon nl2 nt2 mr1 dib v-mid lh-solid blue rotate-infinite"
  />
)

const successIcon = (
  <Icon
    icon={['far', 'check']}
    className="success-icon nl2 mr1 f5 dib v-mid lh-solid green"
  />
)

/**
 * A Button that provides feedback to the user that some action is being performed, and if it succeeded in that action.
 * Helpful for keeping anxious users from clicking multiple times.
 * Use for asynchronous operations that may take some time, but aren't long enough to warrant a separate loading screen.
 * Set `isActing=true` when you are doing something, then `isActing=false` `isSuccess=true` when it succeeds.
 * The button will be disabled and `onClick` events will not be received in either acting or sucess state.
 * Errors should probably be handled with an `InlineNotification` or something similar.
 */
const ActionButton = ({
  className,
  children,
  disabled = false,
  defaultText,
  isActing = false,
  actingText,
  isSuccess = false,
  successText,
  ...props
}: ActionButtonProps): ReactElement => {
  return (
    <Button
      {...props}
      className={`${className ?? ''}`}
      disabled={isActing || disabled || isSuccess}
    >
      {(isActing && (
        <>
          {actingIcon} {actingText ?? children ?? defaultText}
        </>
      )) ||
        (isSuccess && (
          <>
            {successIcon} {successText ?? children ?? defaultText}
          </>
        )) || <>{children ?? defaultText}</>}
    </Button>
  )
}

export default ActionButton
