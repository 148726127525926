import {
  LeftUIContainer,
  PrimaryActionButton,
  UsernameContainer,
} from 'components'
import { RegistrationFlowHeader } from 'components/create-password/RegistrationFlowHeader'
import { AuthError } from 'components/DefaultAuthError'
import { TextField } from 'components/Form/Formik'
import { TitleContent } from 'components/TitleContent'
import { useCookieContext } from 'contexts/CookieContext'
import { Form, Formik } from 'formik'
import { useAnalytics } from 'hooks'
import { AnalyticsEvent, AnalyticsPage, RegistrationFlow } from 'models'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  completeRegistrationsWithEmail,
  COOKIE_EMAIL_DISPLAY_KEY,
  COOKIE_EMAIL_KEY,
  COOKIE_REG_FLOW_KEY,
  CREATE_PASSWORD_ROUTE,
  EMAIL_MATCH_VALIDATION_SCHEMA,
  navigateToMissionLaneHome,
} from 'utilities'

export function EmailOnlyMatchLandingPage() {
  const { page, trackEvent, trackClickEvent } = useAnalytics()
  const navigate = useNavigate()
  const { setCookie } = useCookieContext()
  const analyticsPageName = `${AnalyticsPage.EmailOnlyMatch}`

  useEffect(() => {
    page(analyticsPageName)
    setCookie(COOKIE_REG_FLOW_KEY, RegistrationFlow.EmailOnlyMatch)
  }, [])

  const [email, setEmail] = useState<string>('')
  const [authError, setAuthError] = useState<boolean>(false)
  useState<boolean>(false)

  const createPasswordMutation = completeRegistrationsWithEmail()

  /**
   * Set the email in local state
   * Update cookie values for email and email display
   * @param data - form data
   */
  const submitChangeEmail = () => {
    const body = JSON.stringify({ email })
    createPasswordMutation.mutate(body)
    trackClickEvent({
      event: `${analyticsPageName}: ${AnalyticsEvent.ChangeEmail}`,
    })
  }

  const onChangeEmail = () => {
    setAuthError(false)
  }

  const onChangeConfirmEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    setAuthError(false)
  }

  useEffect(() => {
    if (createPasswordMutation.isSuccess) {
      setCookie(COOKIE_EMAIL_KEY, email)
      setCookie(COOKIE_EMAIL_DISPLAY_KEY, email)
      navigate(CREATE_PASSWORD_ROUTE)
    }
    if (createPasswordMutation.isError) {
      setAuthError(true)
      trackEvent(`${analyticsPageName}: ${AnalyticsEvent.Error}`, {
        event: AnalyticsEvent.ErrorMessage,
      })
    }
  }, [createPasswordMutation.isSuccess, createPasswordMutation.isError])

  return (
    <section className="grid h-screen grid-flow-row grid-cols-4 grid-rows-[min-content_1fr] bg-white lg:auto-rows-fr lg:grid-cols-12 lg:grid-rows-none">
      <LeftUIContainer
        analyticsPage={analyticsPageName}
        icon="clipboard-question"
      />
      <div className="lg:form-max-width col-span-8 justify-items-center lg:my-even-larger">
        <RegistrationFlowHeader
          isSuccessPage={false}
          title="It looks like your email is already in use."
          analyticsPageName={analyticsPageName}
        />
        <div className="form-max-width px-6 lg:px-0">
          <TitleContent
            isSuccessPage={false}
            subtitle="We couldn't activate your account because this email address is already in use. Please provide a new email address to continue."
            title="It looks like your email is already in use."
          />
          <div className="flex flex-col gap-6 pt-6 md:flex-row md:gap-20 md:pt-0 ">
            <UsernameContainer
              className="mx-0 mt-6 bg-blue-wash-light"
              containerClassName="w-full"
              label="Existing Email"
            />
            <Formik
              validationSchema={EMAIL_MATCH_VALIDATION_SCHEMA}
              onSubmit={submitChangeEmail}
              initialValues={{ email: '', confirmEmail: '' }}
            >
              {({ isSubmitting, status, isValid, values, touched }) => (
                <Form className="w-full">
                  <TextField
                    id="email"
                    name="email"
                    label="New Email"
                    inputProps={{
                      autoFocus: true,
                      onChange: onChangeEmail,
                    }}
                    fieldClassName="pb-larger"
                    errorClassName="float-none mt-4"
                    shouldShowValidCheck={touched.email}
                    shouldShowErrorIcon={true}
                  />
                  <TextField
                    id="confirmEmail"
                    name="confirmEmail"
                    label="Confirm Email"
                    shouldShowValidCheck={touched.confirmEmail}
                    shouldShowErrorIcon={true}
                    errorClassName="mt-4"
                    inputProps={{
                      onChange: onChangeConfirmEmail,
                    }}
                  />
                  <>
                    {authError && (
                      <AuthError
                        errors="Please use a different email."
                        className="absolute mt-2"
                      />
                    )}
                  </>
                  <PrimaryActionButton
                    defaultText="Change Email"
                    isSuccess={status === 'success' && !isSubmitting}
                    disabled={!isValid || !values.confirmEmail}
                    onCancel={navigateToMissionLaneHome}
                    type="submit"
                    actingText="Submitting..."
                    successText="Success!"
                    isActing={!!email && createPasswordMutation.isLoading}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  )
}
