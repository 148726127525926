interface Props {
  className?: string
}

export function MagnifyingGlassIcon({ className = '', ...rest }: Props) {
  return (
    <svg
      width="183"
      height="214"
      viewBox="0 0 183 214"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path
        d="M53.2308 143.734C23.8322 123.149 16.6875 82.6291 37.2726 53.2305C57.8577 23.8319 98.3774 16.6872 127.776 37.2723C157.175 57.8574 164.319 98.3772 143.734 127.776C123.149 157.174 82.6294 164.319 53.2308 143.734Z"
        fill="#60CDA9"
      />
      <path
        d="M35.3794 100.222C37.238 110.763 41.9325 120.091 48.539 127.557C61.0167 141.661 80.3118 149.133 100.215 145.624C109.33 144.016 117.537 140.287 124.419 135.033C140.521 122.744 149.376 102.111 145.616 80.7847C140.249 50.3471 111.218 30.0196 80.7776 35.3871C50.3331 40.752 30.0119 69.7817 35.3794 100.222Z"
        fill="white"
      />
      <path
        d="M92.6145 51.2072C92.6145 51.2072 110.562 45.7082 116.294 62.47C122.029 79.2319 117.87 96.0807 120.472 103.027C123.074 109.973 127.5 134.128 122.3 136.054C117.1 137.98 89.5965 129.451 89.5965 129.451L58.6014 132.847C58.6014 132.847 51.3258 126.269 54.2185 114.227C57.1112 102.186 65.5257 102.859 64.8286 80.6274C64.6259 74.2348 69.533 43.95 92.6145 51.2072Z"
        fill="#072D26"
      />
      <path
        d="M48.5419 127.558C61.0196 141.662 80.3148 149.134 100.218 145.624C109.333 144.017 117.54 140.288 124.422 135.034C117.137 131.964 80.9814 117.956 48.5419 127.558Z"
        fill="#072D26"
      />
      <circle cx="108.861" cy="72.0753" r="20.585" fill="#072D26" />
      <path
        d="M92.9459 134.422C93.5011 134.294 93.973 134.175 94.3381 134.079L95.2931 114.98L95.3003 114.835L95.3404 114.696L96.5415 115.042C95.3404 114.696 95.3405 114.696 95.3405 114.695L95.3407 114.695L95.3412 114.693L95.3431 114.686L95.3499 114.663L95.3757 114.576C95.3982 114.5 95.4312 114.39 95.474 114.25C95.5595 113.97 95.6845 113.57 95.8435 113.087C96.161 112.121 96.6166 110.812 97.1671 109.448C97.7152 108.091 98.37 106.647 99.0907 105.425C99.7775 104.261 100.647 103.079 101.699 102.51C102.262 102.205 103.015 101.892 103.885 101.53C108.246 99.7146 115.535 96.6807 116.38 87.4915C116.914 81.6842 116.403 76.0389 115.753 71.8306C115.429 69.7303 115.071 67.9971 114.795 66.7923C114.657 66.1901 114.54 65.7205 114.458 65.4036C114.448 65.3662 114.439 65.331 114.43 65.298C101.33 54.5252 88.6417 54.133 80.4364 59.1147C72.1697 64.1336 68.0157 74.8547 72.5712 87.1721C77.3974 100.213 77.276 110.8 76.6095 118.726C76.4806 120.26 76.3352 121.663 76.2014 122.954C75.9841 125.052 75.7973 126.855 75.7609 128.445C75.7021 131.015 76.0721 132.49 76.9648 133.377C77.9882 134.393 79.5503 135.015 81.4854 135.308C83.4061 135.599 85.5608 135.545 87.6141 135.328C89.6595 135.113 91.5567 134.741 92.9459 134.422Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="2.5"
      />
      <path
        d="M125.507 70.3223C120.93 68.4578 121.779 66.518 121.779 66.518C121.779 66.518 117.173 45.392 96.8527 48.3195C76.5329 51.2471 76.3875 57.2828 76.3875 57.2828C76.3875 57.2828 73.1731 58.2355 69.4092 64.8009C65.6459 71.3699 65.6507 76.7802 65.701 80.0516C65.7859 85.4084 69.2524 93.3669 70.9078 94.3857C72.5632 95.4045 70.8349 92.4289 70.8349 92.4289C70.8349 92.4289 73.8737 97.2505 76.3737 96.8634C78.8771 96.4757 78.5113 71.3038 85.3988 73.6167C85.3988 73.6167 85.7284 71.0984 87.7869 71.8026C89.8453 72.5068 88.6901 82.9383 100.735 82.8916C101.923 82.8862 99.988 80.5236 99.988 80.5236C99.988 80.5236 102.333 81.8149 105.406 81.7529C108.479 81.6909 105.537 79.7565 105.537 79.7565C105.537 79.7565 111.762 80.9543 117.551 80.023C118.517 81.6578 107.871 74.437 110.616 76.6353C111.27 77.1574 125.081 80.2676 124.969 76.7097C124.993 76.6839 125.022 76.6611 125.046 76.6353C125.046 76.6353 128.97 74.254 125.046 73.3882C125.041 73.3998 129.652 72.0123 125.507 70.3223Z"
        fill="#072D26"
      />
      <path
        d="M68.2367 89.5766C67.6217 86.4196 69.6883 82.843 73.225 81.8294C73.3683 81.7898 73.4977 81.708 73.598 81.5935C73.6983 81.4791 73.7652 81.3369 73.7909 81.1838C76.3681 66.1413 87.4396 55.8116 98.3019 55.8697C112.719 55.9537 122.155 74.321 121.534 88.86C120.888 103.935 109.14 121.392 94.8882 119.7C85.0469 118.532 76.2143 108.538 73.7971 95.1288C73.7686 94.9686 73.6947 94.8212 73.5849 94.7059C73.4751 94.5907 73.3346 94.5131 73.182 94.4832C72.0011 94.2373 70.9103 93.6463 70.0354 92.7783C69.1605 91.9102 68.537 90.8004 68.2367 89.5766Z"
        fill="white"
        stroke="#072D26"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M121.897 71.5708C117.853 79.8616 105.816 82.3105 95.0107 77.0405C84.2056 71.7705 78.7244 60.7773 82.7681 52.4865C86.8118 44.1956 98.8491 41.7468 109.654 47.0168C120.459 52.2868 125.94 63.28 121.897 71.5708Z"
        fill="#072D26"
      />
      <path
        d="M95.3853 88.7111C94.6953 92.8343 90.7935 95.6175 86.6703 94.9275C82.5471 94.2375 79.764 90.3357 80.454 86.2125C81.144 82.0893 85.0458 79.3061 89.169 79.9961C93.2921 80.6861 96.0753 84.5879 95.3853 88.7111Z"
        fill="white"
        stroke="#007459"
        strokeWidth="2.5"
      />
      <path
        d="M117.557 92.4231C116.867 96.5463 112.965 99.3294 108.842 98.6394C104.719 97.9495 101.936 94.0476 102.626 89.9244C103.316 85.8012 107.218 83.018 111.341 83.708C115.464 84.398 118.247 88.2999 117.557 92.4231Z"
        fill="white"
        stroke="#007459"
        strokeWidth="2.5"
      />
      <path
        d="M95.9912 89.5137L101.789 90.4839"
        stroke="#007459"
        strokeWidth="2.5"
      />
      <path
        d="M137.448 175.567C138.727 177.365 142.897 176.597 146.585 173.972C150.272 171.347 152.368 167.662 151.086 165.862L131.218 137.939C129.939 136.141 125.769 136.909 122.081 139.534C118.394 142.159 116.298 145.843 117.58 147.644L137.448 175.567Z"
        fill="#60CDA9"
      />
      <path
        d="M156.691 211.999C158.154 214.056 161.999 213.831 165.37 211.43L176.474 203.532C179.846 201.134 181.339 197.559 179.876 195.502L154.02 159.159C152.557 157.102 148.794 157.484 145.419 159.882L134.316 167.781C130.944 170.179 129.368 173.597 130.831 175.654L156.691 211.999Z"
        fill="#072D26"
      />
      <path
        d="M161.69 145.22C161.192 145.22 160.794 144.815 160.794 144.32V140.265C160.794 139.767 161.192 139.365 161.69 139.365C162.189 139.365 162.587 139.767 162.587 140.265V144.32C162.587 144.818 162.189 145.22 161.69 145.22Z"
        fill="#007459"
        stroke="#007459"
      />
      <path
        d="M161.689 156.033C161.191 156.033 160.793 155.628 160.793 155.133V150.626C160.793 150.128 161.191 149.727 161.689 149.727C162.188 149.727 162.586 150.132 162.586 150.626V155.133C162.586 155.631 162.188 156.033 161.689 156.033Z"
        fill="#007459"
        stroke="#007459"
      />
      <path
        d="M168.81 148.536H164.755C164.257 148.536 163.855 148.138 163.855 147.639C163.855 147.141 164.257 146.743 164.755 146.743H168.81C169.308 146.743 169.71 147.141 169.71 147.639C169.71 148.134 169.308 148.536 168.81 148.536Z"
        fill="#007459"
        stroke="#007459"
      />
      <path
        d="M158.448 148.537H153.942C153.444 148.537 153.042 148.139 153.042 147.64C153.042 147.142 153.447 146.744 153.942 146.744H158.448C158.947 146.744 159.348 147.142 159.348 147.64C159.348 148.135 158.943 148.537 158.448 148.537Z"
        fill="#007459"
        stroke="#007459"
      />
      <path
        d="M172.868 156.964C172.484 156.964 172.178 156.653 172.178 156.272V153.15C172.178 152.766 172.484 152.457 172.868 152.457C173.251 152.457 173.558 152.766 173.558 153.15V156.272C173.558 156.655 173.251 156.964 172.868 156.964Z"
        fill="#007459"
        stroke="#007459"
      />
      <path
        d="M172.868 165.29C172.484 165.29 172.178 164.978 172.178 164.597V161.127C172.178 160.744 172.484 160.435 172.868 160.435C173.251 160.435 173.558 160.746 173.558 161.127V164.597C173.558 164.981 173.251 165.29 172.868 165.29Z"
        fill="#007459"
        stroke="#007459"
      />
      <path
        d="M178.35 159.518H175.228C174.844 159.518 174.535 159.211 174.535 158.828C174.535 158.444 174.844 158.138 175.228 158.138H178.35C178.733 158.138 179.043 158.444 179.043 158.828C179.043 159.209 178.733 159.518 178.35 159.518Z"
        fill="#007459"
        stroke="#007459"
      />
      <path
        d="M170.372 159.519H166.903C166.519 159.519 166.21 159.212 166.21 158.829C166.21 158.445 166.522 158.139 166.903 158.139H170.372C170.756 158.139 171.065 158.445 171.065 158.829C171.065 159.21 170.753 159.519 170.372 159.519Z"
        fill="#007459"
        stroke="#007459"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.667 166.167C180.495 166.167 181.167 165.495 181.167 164.667C181.167 163.838 180.495 163.167 179.667 163.167C178.838 163.167 178.167 163.838 178.167 164.667C178.167 165.495 178.838 166.167 179.667 166.167ZM179.667 167.292C181.116 167.292 182.292 166.116 182.292 164.667C182.292 163.217 181.116 162.042 179.667 162.042C178.217 162.042 177.042 163.217 177.042 164.667C177.042 166.116 178.217 167.292 179.667 167.292Z"
        fill="#007459"
        stroke="#007459"
      />
      <path
        d="M35.7915 23.0802C36.6459 23.0802 37.3281 22.3857 37.3281 21.5375V14.5857C37.3281 13.7314 36.6459 13.043 35.7915 13.043C34.9371 13.043 34.2549 13.7314 34.2549 14.5857V21.5375C34.2549 22.3918 34.9371 23.0802 35.7915 23.0802Z"
        fill="#007459"
        stroke="#007459"
        strokeWidth="1.5"
      />
      <path
        d="M35.7915 41.6184C36.6459 41.6184 37.3281 40.9238 37.3281 40.0756V32.3494C37.3281 31.4951 36.6459 30.8066 35.7915 30.8066C34.9371 30.8066 34.2549 31.5012 34.2549 32.3494V40.0756C34.2549 40.93 34.9371 41.6184 35.7915 41.6184Z"
        fill="#007459"
        stroke="#007459"
        strokeWidth="1.5"
      />
      <path
        d="M23.5846 28.7656H30.5363C31.3907 28.7656 32.0791 28.0834 32.0791 27.229C32.0791 26.3746 31.3907 25.6924 30.5363 25.6924H23.5846C22.7302 25.6924 22.0418 26.3746 22.0418 27.229C22.0418 28.0772 22.7302 28.7656 23.5846 28.7656Z"
        fill="#007459"
        stroke="#007459"
        strokeWidth="1.5"
      />
      <path
        d="M41.3482 28.7656H49.0744C49.9288 28.7656 50.6172 28.0834 50.6172 27.229C50.6172 26.3746 49.9226 25.6924 49.0744 25.6924H41.3482C40.4938 25.6924 39.8054 26.3746 39.8054 27.229C39.8054 28.0772 40.5 28.7656 41.3482 28.7656Z"
        fill="#007459"
        stroke="#007459"
        strokeWidth="1.5"
      />
      <path
        d="M16.628 43.2159C17.2858 43.2159 17.811 42.6812 17.811 42.0282V36.6761C17.811 36.0183 17.2858 35.4883 16.628 35.4883C15.9702 35.4883 15.4449 36.0183 15.4449 36.6761V42.0282C15.4449 42.6859 15.9702 43.2159 16.628 43.2159Z"
        fill="#007459"
        stroke="#007459"
        strokeWidth="1.5"
      />
      <path
        d="M16.628 57.488C17.2858 57.488 17.811 56.9532 17.811 56.3002V50.3518C17.811 49.6941 17.2858 49.1641 16.628 49.1641C15.9702 49.1641 15.4449 49.6988 15.4449 50.3518V56.3002C15.4449 56.958 15.9702 57.488 16.628 57.488Z"
        fill="#007459"
        stroke="#007459"
        strokeWidth="1.5"
      />
      <path
        d="M7.22963 47.5927H12.5818C13.2395 47.5927 13.7695 47.0674 13.7695 46.4096C13.7695 45.7518 13.2395 45.2266 12.5818 45.2266H7.22963C6.57186 45.2266 6.04185 45.7518 6.04185 46.4096C6.04185 47.0626 6.57186 47.5927 7.22963 47.5927Z"
        fill="#007459"
        stroke="#007459"
        strokeWidth="1.5"
      />
      <path
        d="M20.9058 47.5936H26.8542C27.512 47.5936 28.042 47.0684 28.042 46.4106C28.042 45.7528 27.5073 45.2275 26.8542 45.2275H20.9058C20.2481 45.2275 19.7181 45.7528 19.7181 46.4106C19.7181 47.0636 20.2528 47.5936 20.9058 47.5936Z"
        fill="#007459"
        stroke="#007459"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.542 18.5597C23.1218 18.5597 21.9706 17.4084 21.9706 15.9883C21.9706 14.5681 23.1218 13.4169 24.542 13.4169C25.9622 13.4169 27.1134 14.5681 27.1134 15.9883C27.1134 17.4084 25.9622 18.5597 24.542 18.5597ZM24.542 20.4883C22.0567 20.4883 20.042 18.4736 20.042 15.9883C20.042 13.503 22.0567 11.4883 24.542 11.4883C27.0273 11.4883 29.042 13.503 29.042 15.9883C29.042 18.4736 27.0273 20.4883 24.542 20.4883Z"
        fill="#007459"
        stroke="#007459"
        strokeWidth="1.5"
      />
    </svg>
  )
}
