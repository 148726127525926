import { useAnalytics } from 'hooks'
import { AnalyticsEvent, AnalyticsPage } from 'models'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  completeRegistrations,
  CREATE_PASSWORD_ROUTE,
  ERROR_ROUTE,
} from 'utilities'
import { Modal } from '../Modal'

interface Props {
  open: boolean
  onClose: () => void
}

export function EmailDeconflictOptionalModal({ open, onClose }: Props) {
  const title = 'Create a new profile?'
  const buttonText = 'Yes, Continue'

  const { trackClickEvent, trackEvent } = useAnalytics()
  const [triggerComplete, setTriggerComplete] = useState<boolean>(false)
  const {
    data: completeRegistrationData,
    isLoading,
    error,
  } = completeRegistrations({ enabled: triggerComplete })
  const navigate = useNavigate()
  useEffect(() => {
    if (completeRegistrationData?.registrationComplete) {
      navigate(CREATE_PASSWORD_ROUTE)
    }
    if (error) {
      navigate(ERROR_ROUTE)
      trackEvent(
        `${AnalyticsPage.CreateNewProfileModal}: ${AnalyticsEvent.Error}`,
        {
          event: AnalyticsEvent.ErrorMessage,
        }
      )
    }
  }, [completeRegistrationData])

  if (!open) return null

  const continueToSetPassword = () => {
    trackClickEvent({
      event: `${AnalyticsPage.CreateNewProfileModal}: ${AnalyticsEvent.ContinueToSetPassword}`,
    })
    setTriggerComplete(true)
  }

  return (
    <Modal
      onClose={onClose}
      title={title}
      subtitleMarkup={
        <p className="text-standard text-ink">
          Continuing with a new email means{' '}
          <strong>
            this account will be separate from your existing profile.
          </strong>{' '}
          Do you want to continue with a new email?
        </p>
      }
      buttonText={buttonText}
      onClick={continueToSetPassword}
      isSuccess={completeRegistrationData?.registrationComplete}
      isActing={triggerComplete && isLoading}
    />
  )
}
