import { FullPageLayout, Loading } from 'components'
import {
  authCompleteRegistrations,
  COOKIE_EMAIL_KEY,
  COOKIE_PRODUCT_REDIRECT_KEY,
  ERROR_ROUTE,
  notifyBugsnag,
} from 'utilities'
import { Navigate } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import { useCookieContext } from 'contexts/CookieContext'
import { useAnalytics } from 'hooks'
import { AnalyticsPage } from 'models'

export function FullMatchSuccessPage() {
  const { getCookie, clearRegCookies } = useCookieContext()
  const { identify, page } = useAnalytics()
  const analyticsPageName = `${AnalyticsPage.FullMatch} ${AnalyticsPage.Success}`
  const { isLoading, error, data } = authCompleteRegistrations(true)
  const hasError = useMemo(
    () => error || (!!data && !data.registrationComplete),
    [error, data]
  )

  useEffect(() => {
    if (data) {
      clearRegCookies()
      const email = getCookie(COOKIE_EMAIL_KEY)
      identify(data.customerId, email)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      notifyBugsnag({
        error: error as Error,
        name: 'CompleteRegistration',
        prefix: 'FullMatchSuccessPage',
      })
    }
  }, [error])

  useEffect(() => {
    page(analyticsPageName)
  }, [])

  return (
    <>
      {isLoading && <Loading />}
      {hasError && <Navigate to={ERROR_ROUTE} />}
      {data?.registrationComplete && (
        <FullPageLayout
          title="You've linked your new account to your existing profile."
          subtitle="Check your dashboard to see the update."
          bgColor="bg-teal-wash"
          isSuccess
          icon="confetti"
          redirect={getCookie(COOKIE_PRODUCT_REDIRECT_KEY)}
          external={true}
          analyticsPage={analyticsPageName}
        />
      )}
    </>
  )
}
