import { PrimaryActionButton } from './PrimaryActionButton'

interface Props {
  className?: string
  onClose: () => void
  title: string
  subtitleMarkup: React.ReactNode
  buttonText: string
  onClick: any
  isSuccess: boolean
  isActing: boolean
}

export function Modal({
  className = '',
  onClose,
  title,
  subtitleMarkup,
  buttonText,
  onClick,
  isSuccess,
  isActing,
}: Props) {
  return (
    <>
      <div
        className={`modalContainer absolute inset-0 z-50 m-auto max-h-[247px] max-w-[640px] bg-white p-8 opacity-100 ${className}`}
      >
        <div>
          <div className="flex justify-between">
            <h3 className="m-h1 pb-4">{title}</h3>
            <p className="cursor-pointer text-blue" onClick={onClose}>
              X
            </p>
          </div>
          {subtitleMarkup}
        </div>
        <div className="flex items-center justify-end pt-6">
          <div className="mx-12">
            <p className="cursor-pointer text-blue" onClick={onClose}>
              Back
            </p>
          </div>
          <div className="fit-content">
            <PrimaryActionButton
              className="btn mt-0 px-16"
              defaultText={buttonText}
              isSuccess={isSuccess}
              onCancel={onClose}
              onClick={onClick}
              actingText="Loading..."
              successText="Success!"
              isActing={isActing}
            />
          </div>
        </div>
      </div>
    </>
  )
}
