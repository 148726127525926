import { Divider, TextAndLink } from 'components'
import Button from 'components/Form/Button'
import { useAnalytics } from 'hooks'
import { Navigate, useNavigate } from 'react-router-dom'
import { ERROR_ROUTE, SUCCESSFUL_NEW_ACCT_ROUTE } from 'utilities'

interface Props {
  buttonText: string
  className?: string
  redirect: string
  showDivider?: boolean
  external: boolean
  analyticsPage: string
  buttonClass?: string
}

export function FMCtaContent({
  buttonText,
  className = '',
  showDivider = false,
  redirect,
  external,
  analyticsPage,
  buttonClass = 'mt-16',
}: Props) {
  const navigate = useNavigate()
  const { trackClickEvent } = useAnalytics()
  const navigateToUrl = () => {
    trackClickEvent({
      event: `${analyticsPage}: ${buttonText}`,
    })
    external
      ? window.location.replace(`https://${redirect}`)
      : navigate(redirect ?? '')
  }
  return (
    <div className={className}>
      <>{!redirect && <Navigate to={ERROR_ROUTE} />}</>
      <Button className={buttonClass} onClick={navigateToUrl}>
        {buttonText}
      </Button>
      <Divider
        text="Or"
        className={`text-center ${showDivider ? `` : `hidden`}`}
      />
      <TextAndLink
        text={`Forgot your email?${' '}`}
        external
        link={SUCCESSFUL_NEW_ACCT_ROUTE}
        className={`text-center ${showDivider ? `` : `hidden`}`}
        linkText="Go to find email"
        analyticsEventName={''}
      />
    </div>
  )
}
