import { createContext, useContext, useState } from 'react'
import { useLocalStorage } from 'hooks'
import { CreatePasswordConfig } from 'models'
import { USERNAME_KEY } from 'utilities'

export const CreatePasswordContext = createContext<CreatePasswordConfig>(
  getInitialValue()
)
CreatePasswordContext.displayName = 'CreatePassword'

export const useCreatePasswordContext = () => useContext(CreatePasswordContext)

interface Props {
  children: React.ReactNode
}

export const CreatePasswordContextProvider = ({ children }: Props) => {
  const initial = getInitialValue()

  const [isPasswordCreated, setIsPasswordCreated] = useState<boolean>(
    initial.isPasswordCreated
  )
  const [title] = useState<string>(initial.title)
  const [subtitle] = useState<string>(initial.subtitle ?? '')

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [username] = useLocalStorage<string>(USERNAME_KEY, '')

  return (
    <CreatePasswordContext.Provider
      value={{
        subtitle,
        title,
        isSubmitting,
        setIsSubmitting,
        username,
        isPasswordCreated,
        setIsPasswordCreated,
      }}
    >
      {children}
    </CreatePasswordContext.Provider>
  )
}

function getInitialValue(): CreatePasswordConfig {
  return {
    title: 'Create a password',
    username: '',
    isPasswordCreated: false,
  }
}
