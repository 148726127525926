import { RegistrationConfig, Resolution } from 'models'
import { createContext, useContext, useState } from 'react'

const RegistrationContext = createContext<RegistrationConfig>(getInitialValue())
RegistrationContext.displayName = 'Registration'

export const useRegistrationContext = () => useContext(RegistrationContext)

interface Props {
  children: React.ReactNode
}

export const RegistrationContextProvider = ({ children }: Props) => {
  const [email, setEmail] = useState('')
  const [productCode, setProductCode] = useState('')
  const [productRedirectUrl, setRedirectUrl] = useState('')
  const [resolution, setResolution] = useState<Resolution>('')

  return (
    <RegistrationContext.Provider
      value={{
        email,
        setEmail,
        productCode,
        setProductCode,
        productRedirectUrl,
        setRedirectUrl,
        resolution,
        setResolution,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  )
}

function getInitialValue(): RegistrationConfig {
  return {
    email: '',
    resolution: '',
    setEmail: () => null,
    setProductCode: () => null,
    setRedirectUrl: () => null,
    setResolution: () => null,
  }
}
