import React from 'react'

interface ErrorMessageProps {
  error: React.ReactNode
  className?: string
}
export const ErrorMessage = ({ error, className = '' }: ErrorMessageProps) => (
  <div className={`${className} error`}>{error}</div>
)

interface ErrorsProps {
  className?: string
  errors: React.ReactNode[]
  children: React.ReactNode
}

const Errors = ({ className = '', children, errors }: ErrorsProps) => (
  <div className={className}>
    {children}
    {errors.map((error, i) => (
      <ErrorMessage error={error} className={className} key={i} />
    ))}
  </div>
)

export default Errors
