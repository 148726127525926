import { LeftUIContainer } from 'components'

interface Props {
  analyticsPage: string
  children: React.ReactNode
}

export function LayoutContainer({ analyticsPage, children }: Props) {
  return (
    <section className="grid h-screen grid-flow-row grid-cols-4 grid-rows-[min-content_1fr] bg-white lg:auto-rows-fr lg:grid-cols-12 lg:grid-rows-none">
      <LeftUIContainer analyticsPage={analyticsPage} />
      <div className="col-span-8 justify-items-center self-center text-center lg:my-even-larger lg:mx-very-large">
        {children}
      </div>
    </section>
  )
}
