import Cookies from 'js-cookie'
import { getEnvValue } from './functions'

export const USERNAME_KEY = 'username'

/**
 * Routes
 */
export const CREATE_PASSWORD_ROUTE = '/create-password'
export const ERROR_ROUTE = '/error'
export const TIMEOUT_ROUTE = '/timeout'
export const EMAIL_DECONFLICT_REQUIRED_ROUTE = 'new-email'
export const FULL_MATCH_ROUTE = 'full-match'
export const LANDING_PAGE_ROUTE = '/'
export const NO_MATCH_SUCCESS_ROUTE = '/no-match-success'
export const PLAYGROUND_ROUTE = 'playground'
export const SUCCESSFUL_NEW_ACCT_ROUTE = '/authenticated-new-user'
export const FULL_MATCH_SUCCESS_ROUTE = '/full-match-success'
export const EMAIL_DECONFLICT_OPTIONAL_ROUTE = 'email-deconflict-optional'
export const MAINTENANCE_ROUTE = '/maintenance'

/**
 * Mission Lane URLs
 */
export const ML_HOME = 'https://www.missionlane.com'
export const ML_HELP_LINK =
  'https://www.missionlane.com/contact-us?topic=help%2Botp'
export const ML_REGISTRATION_HOME = getEnvValue(
  'REACT_APP_ML_REGISTRATION_HOME',
  '/error'
)
export const ML_LOGIN_HOME = getEnvValue('REACT_APP_ML_LOGIN_HOME', '/error')

/**
 * Products & Valid Redirect URLs
 */
export const PRODUCT_CBA_KEY = 'CreditBuilderLoans'
export const PRODUCT_CREDIT_CARD_KEY = 'CreditCard'
export const REDIRECT_MAP = new Map([
  [PRODUCT_CBA_KEY, getEnvValue('REACT_APP_PRODUCT_CBA_REDIRECT_URL')],
  [PRODUCT_CREDIT_CARD_KEY, getEnvValue('REACT_APP_PRODUCT_CC_REDIRECT_URL')],
])

/**
 * Cookies
 */
export const COOKIE_EMAIL_KEY = 'email'
export const COOKIE_EMAIL_DISPLAY_KEY = 'email_display'
export const COOKIE_ID_TOKEN_KEY = 'id_token'
export const COOKIE_REG_TOKEN_KEY = 'registration_token'
export const COOKIE_PRODUCT_REDIRECT_KEY = 'product_redirect_url'
/* Used for analytics tracking event and page names */
export const COOKIE_REG_FLOW_KEY = 'registration_flow'

export const COOKIE_ATTRIBUTES: Cookies.CookieAttributes = {
  domain: getEnvValue('REACT_APP_ML_COOKIE_DOMAIN'),
  sameSite: 'None',
  secure: true,
}

/**
 * Registration API config
 */
export const REG_API_GET_REGISTRATIONS_KEY = 'get_registrations'
export const REG_API_AUTH_COMPLETE_REGISTRATIONS_KEY =
  'auth_complete_registrations'
export const REG_API_COMPLETE_REGISTRATIONS_KEY = 'complete_registrations'
export const REG_API_GET_ENDPOINT = 'registrations'
export const REG_API_AUTH_COMPLETE_ENDPOINT =
  'authenticated/registrations/complete'
export const REG_API_COMPLETE_ENDPOINT = 'registrations/complete'
export const REG_API_SET_PASSWORD_KEY = 'create_password'
export const REG_API_SET_PASSWORD_ENDPOINT = 'registrations/passwords'

/**
 * Launch Darkly
 */
export const LD_PRE_AUTH_USER_KEY = 'pre_auth_user_key'
