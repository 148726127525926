import App from 'App'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import { LD_PRE_AUTH_USER_KEY } from 'utilities/constants'
import { bugsnagInit, getEnvValue } from 'utilities'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const ErrorBoundary = bugsnagInit()
root.render(
  <React.StrictMode>
    <LDProvider
      clientSideID={getEnvValue('REACT_APP_LAUNCHDARKLY_CLIENT_ID') ?? ''}
      user={{ key: LD_PRE_AUTH_USER_KEY, anonymous: true }}
    >
      <BrowserRouter>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </LDProvider>
  </React.StrictMode>
)
