interface Props {
  className?: string
}

export function WingedHourglassIcon({ className = '' }: Props) {
  return (
    <svg
      width="299"
      height="280"
      viewBox="0 0 299 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M227.777 96.2266C224.23 96.7752 221.633 99.9787 221.975 103.621C222.402 108.238 220.41 113.373 217.116 116.845C212.252 122.08 205.435 124.822 199.121 128.084L199.017 128.133C192.289 131.593 186.979 137.358 183.853 144.239C183.078 145.94 182.303 147.641 181.632 149.293C182.788 147.727 184.96 146.692 186.965 146.37C189.64 145.857 192.481 146.089 195.111 145.216C200.759 143.349 203.642 137.155 205.873 131.589L202.969 140.266C202.098 142.964 203.763 145.786 206.599 146.274C207.569 146.446 208.262 146.433 209.578 147.328L213.959 126.721C213.959 126.721 213.563 131.476 212.913 138.889C212.585 142.724 215.701 146.059 219.571 146.054C220.699 146.024 221.46 145.091 223.508 146.588C224.335 137.949 222.408 128.593 219.045 120.619C221.319 125.117 223.669 129.641 225.944 134.138C228.218 138.636 232.705 141.254 237.382 139.216C238.596 138.701 240.103 137.602 242.332 137.618C236.529 129.982 230.298 121.852 224.495 114.217C229.727 118.129 235.036 122.068 240.268 125.981C242.644 127.766 245.249 129.632 248.269 129.842C253.209 130.215 257.288 123.071 263.149 123.512C251.548 118.893 237.176 113.121 225.575 108.501C234.1 110.401 242.624 112.3 251.225 114.227C256.483 115.4 263.389 117.5 268.636 116.522C273.909 115.467 274.45 109.819 281.574 105.726C264.598 104.873 244.35 103.55 227.374 102.697C227.374 102.697 245.039 102.334 255.346 102.116C264.372 101.874 272.297 101.585 280.979 99.1606C289.688 96.6595 297.839 89.6672 298.442 80.6912C275.098 87.3757 251.5 92.596 227.777 96.2266Z"
        fill="#E8DFDA"
      />
      <path
        d="M116.053 56.7009C118.463 59.3574 118.464 63.4797 115.903 66.095C112.664 69.4147 110.977 74.6579 111.35 79.4272C111.832 86.5543 115.403 92.9705 118.255 99.4758L118.304 99.5791C121.354 106.498 121.849 114.316 119.944 121.63C119.475 123.439 119.006 125.248 118.487 126.954C118.574 125.009 117.538 122.839 116.183 121.329C114.427 119.248 112.074 117.643 110.579 115.31C107.366 110.309 109.027 103.683 110.8 97.9547L107.591 106.524C106.568 109.168 103.496 110.314 100.985 108.91C100.123 108.433 99.5928 107.988 98.0063 107.856L107.582 89.087C107.582 89.087 104.894 93.0314 100.73 99.2015C98.5694 102.388 94.047 103.021 91.0425 100.583C90.1848 99.8506 90.1816 98.6472 87.6466 98.5233C92.4456 91.2895 99.8369 85.2305 107.473 81.1475C102.873 83.2129 98.1963 85.2512 93.596 87.3166C88.9956 89.3819 83.8589 88.5951 81.5084 84.0707C80.8893 82.907 80.4103 81.1058 78.6677 79.7164C87.9878 77.4315 97.9508 75.0304 107.271 72.7455C100.74 72.4962 94.1332 72.2198 87.6025 71.9705C84.6316 71.8641 81.4315 71.6767 78.9522 69.9407C74.8783 67.1254 76.2083 59.0078 71.3754 55.6663C83.3007 59.37 98.1063 63.921 110.032 67.6247C102.21 63.7411 94.3889 59.8576 86.4911 55.947C81.6662 53.553 74.9763 50.8427 71.5151 46.7841C68.0809 42.6492 71.2187 37.9194 68.2602 30.2599C81.9903 40.2703 98.5593 51.972 112.289 61.9824C112.289 61.9824 98.79 50.5937 90.9176 43.9439C84.0557 38.081 78.0785 32.8747 72.8588 25.5317C67.6661 18.1123 65.7354 7.55301 70.9211 0.198404C84.8521 20.0702 99.9034 38.9643 116.053 56.7009Z"
        fill="#E8DFDA"
      />
      <path
        d="M144.628 170.996L144.09 172.514L145.441 173.39C161.418 183.752 168.412 206.785 160.501 229.095C160.427 229.304 160.354 229.51 160.281 229.713L82.9564 202.366C83.0271 202.158 83.0987 201.952 83.1717 201.746C91.1916 179.128 111.585 165.559 130.79 168.029L132.426 168.239L132.977 166.685L135.03 160.895L135.574 159.362L134.201 158.49C118.017 148.211 110.891 125.031 118.843 102.603C118.918 102.394 118.991 102.188 119.064 101.985L196.388 129.332C196.317 129.54 196.246 129.746 196.173 129.952C188.204 152.426 167.982 165.959 148.898 163.714L147.278 163.523L146.733 165.061L144.628 170.996Z"
        fill="#F9F8F7"
        stroke="#072D26"
        strokeWidth="4.0847"
      />
      <path
        d="M111.658 83.9605L114.163 76.8941L215.486 112.734L212.981 119.801C212.007 122.547 208.993 123.997 206.23 123.02L114.876 90.7056C112.128 89.7337 110.679 86.7219 111.658 83.9605Z"
        fill="#F9F8F7"
        stroke="#072D26"
        strokeWidth="4.0847"
      />
      <path
        d="M167.695 247.713L165.19 254.78L63.8427 218.931L66.3479 211.865C67.3214 209.119 70.3353 207.668 73.0984 208.645L164.477 240.968C167.225 241.94 168.674 244.952 167.695 247.713Z"
        fill="#F9F8F7"
        stroke="#072D26"
        strokeWidth="4.0847"
      />
      <path
        d="M157.767 195.107C157.594 192.21 136.536 182.774 136.536 182.774L145.014 158.862C162.746 164.392 185.804 142.254 185.804 142.254L119.362 130.074C125.234 146.333 127.609 151.479 140.506 157.267L132.036 181.155C132.036 181.155 95.4007 190.025 92.6789 193.316C89.4656 197.223 89.3458 198.611 87.5246 203.747C87.2583 204.498 87.0163 205.258 86.7742 206.018L156.856 228.08C156.589 228.831 157.146 227.338 156.856 228.08C158.919 222.185 161.381 206.85 157.767 195.107Z"
        fill="#007459"
      />
      <path
        d="M204.863 124.724L115.35 93.0596L112.729 100.452L202.242 132.117L204.863 124.724Z"
        fill="#072D26"
      />
      <path
        d="M166.591 231.238L77.0781 199.573L74.4576 206.966L163.971 238.63L166.591 231.238Z"
        fill="#072D26"
      />
    </svg>
  )
}
