import { BackIcon } from 'components'

interface Props {
  isSuccessPage: boolean
  subtitle?: string
  title: string
}

export function TitleContent({ isSuccessPage, subtitle = '', title }: Props) {
  return (
    <div
      className={`pb-small lg:pb-large ${
        isSuccessPage ? 'success pt-7 text-center' : ''
      }`}
    >
      {isSuccessPage && <BackIcon className="mx-auto mb-8 lg:mb-28" />}
      <h3 className={`pb-3 lg:block ${isSuccessPage ? 'block' : 'hidden'}`}>
        {title}
      </h3>
      {subtitle && <p className="text-standard text-ink">{subtitle}</p>}
    </div>
  )
}
