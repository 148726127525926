import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { serializeError } from 'serialize-error'

/**
 *
 * @param error - logged into bugsnag message
 * @param name - identification  by bugsnag
 * @param prefix - string in front in name that allows bugsnag to identify more specific errors
 * @returns NotifiableError - object wih name and a message containing a json stringified serialized error
 */
export const notifyBugsnag = ({
  error,
  name,
  prefix,
}: {
  error: Error
  name: string
  prefix?: string
}): Extract<{ name: string; message: string }, NotifiableError> => {
  const formattedMessage = JSON.stringify(serializeError(error), null, 2)
  const bugsnagName = `${prefix ? `${prefix}-` : ''}${name}`
  const notifiableError = {
    name: bugsnagName,
    message: formattedMessage,
  }
  Bugsnag.notify(notifiableError)
  return notifiableError
}
