import { Playground } from 'components'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LandingPage } from './LandingPage'
import { PageNotFound } from './PageNotFound'
import { ErrorPage } from './ErrorPage'
import { FullMatchLandingPage } from './FullMatchLandingPage'
import { FullMatchSuccessPage } from './FullMatchSuccessPage'
import { NoMatchSuccessPage } from './NoMatchSuccessPage'
import {
  CREATE_PASSWORD_ROUTE,
  EMAIL_DECONFLICT_OPTIONAL_ROUTE,
  EMAIL_DECONFLICT_REQUIRED_ROUTE,
  ERROR_ROUTE,
  FULL_MATCH_ROUTE,
  FULL_MATCH_SUCCESS_ROUTE,
  LANDING_PAGE_ROUTE,
  MAINTENANCE_ROUTE,
  NO_MATCH_SUCCESS_ROUTE,
  PLAYGROUND_ROUTE,
  TIMEOUT_ROUTE,
} from 'utilities'
import { CreateNewPassword } from 'components/create-password/forms'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { MaintenancePage } from './MaintenancePage'
import { EmailDeconflictOptional } from './EmailDeconflictOptionalPage'
import { EmailOnlyMatchLandingPage } from './EmailOnlyMatchLandingPage'

function MaintenanceUrl() {
  return (
    <Routes>
      <Route path={MAINTENANCE_ROUTE} element={<MaintenancePage />} />
      <Route path="*" element={<Navigate to={MAINTENANCE_ROUTE} />} />
    </Routes>
  )
}

function ApplicationUrls() {
  return (
    <Routes>
      <Route path={LANDING_PAGE_ROUTE} element={<LandingPage />} />
      <Route path={PLAYGROUND_ROUTE} element={<Playground />} />
      <Route path={FULL_MATCH_ROUTE} element={<FullMatchLandingPage />} />
      <Route
        path={FULL_MATCH_SUCCESS_ROUTE}
        element={<FullMatchSuccessPage />}
      />
      <Route path={NO_MATCH_SUCCESS_ROUTE} element={<NoMatchSuccessPage />} />
      <Route path={CREATE_PASSWORD_ROUTE} element={<CreateNewPassword />} />
      <Route
        path={EMAIL_DECONFLICT_OPTIONAL_ROUTE}
        element={<EmailDeconflictOptional />}
      />
      <Route
        path={EMAIL_DECONFLICT_REQUIRED_ROUTE}
        element={<EmailOnlyMatchLandingPage />}
      />
      <Route path={ERROR_ROUTE} element={<ErrorPage />} />
      <Route path={TIMEOUT_ROUTE} element={<ErrorPage isTimeout />} />
      <Route path={MAINTENANCE_ROUTE} element={<MaintenancePage />} />
      {/* 👇️ only match this when no other routes match */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export function UrlRoutes() {
  const { enableRegistrationMaintenance } = useFlags()
  if (enableRegistrationMaintenance) {
    return <MaintenanceUrl />
  }
  return <ApplicationUrls />
}
