import PropTypes from 'prop-types'

interface ErrorPanelProps {
  className: string
  errors: string[] | JSX.Element | string | unknown | undefined
}

const ErrorPanel = ({ className, errors }: ErrorPanelProps) => {
  const errorArray = Array.isArray(errors) ? errors : [errors]

  if (!errorArray.length) {
    return <div />
  }
  return (
    <div className={`bg-white ${className}`}>
      <ul className="">
        {
          // eslint-disable-next-line react/no-array-index-key
        }
        {errorArray.map((error, i) => (
          <li key={i}>{error}</li>
        ))}
      </ul>
    </div>
  )
}

ErrorPanel.defaultProps = {
  className: '',
  errors: null,
}

ErrorPanel.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default ErrorPanel
