import { useSnapshot } from 'hooks'
import React, { InputHTMLAttributes } from 'react'

interface SnapshotFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  inputRef?: React.LegacyRef<HTMLInputElement>
}

/**
 * SnapshotInput is used as a safe way to prevent mutations in our form fields.
 * This component replace the onChange property for a fake method if the `takeSnapshot` flag is on.
 * Removing the onChange controller prevents any modifications in the top-level state, even if the input is enabled again.
 * SnapshotInput use `useSnapshot` hook to know if in the flag `takeSnapshot` is `true` at the Formik's status object,
 * this feature is to simplify the way to disabled all the fields in a form.
 */
const SnapshotInput = ({
  inputRef,
  onChange,
  ...props
}: SnapshotFieldProps) => {
  const takeSnapshot = useSnapshot()
  const safeOnChange = takeSnapshot ? () => {} : onChange
  const extendProps = takeSnapshot ? { readOnly: props.readOnly ?? true } : {}

  return (
    <input
      {...props}
      {...extendProps}
      onChange={safeOnChange}
      ref={inputRef}
      className={`w-full appearance-none rounded border-grey-lighter p-3 text-gray-900 ${
        props.className ?? ''
      }`}
    />
  )
}

export default SnapshotInput
