import { FullPageLayout } from 'components'
import { useCookieContext } from 'contexts/CookieContext'
import { useAnalytics } from 'hooks'
import { AnalyticsPage } from 'models'
import { useEffect } from 'react'
import {
  COOKIE_PRODUCT_REDIRECT_KEY,
  COOKIE_REG_FLOW_KEY,
  ML_LOGIN_HOME,
  ML_REGISTRATION_HOME,
} from 'utilities'

export function NoMatchSuccessPage() {
  const { getCookie, clearRegCookies } = useCookieContext()
  const registrationFlow = getCookie(COOKIE_REG_FLOW_KEY)
  const { page } = useAnalytics()
  const analyticsPageName = `${AnalyticsPage.RegistrationPassword} ${registrationFlow} ${AnalyticsPage.Success}`
  useEffect(() => {
    clearRegCookies()
    page(analyticsPageName)
  }, [])

  return (
    <FullPageLayout
      subtitle="We've successfully created your profile."
      bgColor="bg-teal-wash"
      isSuccess
      showEmail
      icon="confetti"
      external={true}
      redirect={`${ML_LOGIN_HOME}?redirect=${
        getCookie(COOKIE_PRODUCT_REDIRECT_KEY) ?? ML_REGISTRATION_HOME
      }`}
      analyticsPage={analyticsPageName}
      buttonText="Continue to Log In"
      buttonClass="mt-larger"
    />
  )
}
