import { ExternalRedirect, Loading } from 'components'
import { useRegistrationContext } from 'contexts'
import { useEffect, useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import {
  FULL_MATCH_ROUTE,
  CREATE_PASSWORD_ROUTE,
  getRegistrations,
  ERROR_ROUTE,
  COOKIE_EMAIL_KEY,
  REDIRECT_MAP,
  COOKIE_PRODUCT_REDIRECT_KEY,
  authCompleteRegistrations,
  COOKIE_ID_TOKEN_KEY,
  notifyBugsnag,
  EMAIL_DECONFLICT_OPTIONAL_ROUTE,
  COOKIE_EMAIL_DISPLAY_KEY,
  EMAIL_DECONFLICT_REQUIRED_ROUTE,
  COOKIE_REG_FLOW_KEY,
} from 'utilities'
import { useCookieContext } from 'contexts/CookieContext'
import { useAnalytics } from 'hooks'
import { AnalyticsPage, RegistrationFlow } from 'models'

/**
 * The landing page route does the following:
 *    display the loading component while making a call to GET registration
 *    based on the GET response, redirect to the appropriate success route
 *    OR display the appropriate error
 */
export function LandingPage() {
  const { identify, page } = useAnalytics()
  const { getCookie, setCookie, removeCookie, clearRegCookies } =
    useCookieContext()
  const { setProductCode, setEmail, email, setResolution } =
    useRegistrationContext()
  const { isLoading, error, data } = getRegistrations()
  const isIdTokenPresent = !!getCookie(COOKIE_ID_TOKEN_KEY)

  // Only make the call to auth complete when an id token exists and the resolution is 'LOGIN'
  const shouldFetchAuthComplete = useMemo(() => {
    return isIdTokenPresent && data && data.resolution === 'LOGIN'
  }, [data])
  const {
    data: authCompleteData,
    isLoading: isAuthCompleteLoading,
    error: authCompleteError,
  } = authCompleteRegistrations(shouldFetchAuthComplete)

  const shouldShowError = useMemo(() => {
    return error ?? (data && data.resolution === 'REGISTER')
  }, [data, error])

  const shouldRedirectToLogin = useMemo(() => {
    if (authCompleteError) {
      removeCookie(COOKIE_ID_TOKEN_KEY)
      notifyBugsnag({
        error: error as Error,
        name: 'CompleteRegistration',
        prefix: 'LandingPage',
      })
    }
    return !!authCompleteError
  }, [authCompleteData, authCompleteError])

  /**
   * Sets isRegistrationComplete = true when auth complete call is finished.
   * Used to ensure those with an idToken do not get directed to the full match page.
   */
  const isRegistrationComplete = useMemo(() => {
    if (authCompleteData?.customerId) {
      identify(authCompleteData.customerId, email)
    }
    return (
      shouldFetchAuthComplete &&
      // Since the Auth Complete endpoint returns true/false as a string, we check for the existence of a string
      typeof authCompleteData?.registrationComplete === 'string'
    )
  }, [authCompleteData])

  useEffect(() => {
    page(`${AnalyticsPage.Registration} ${AnalyticsPage.Landing}`)
  }, [])

  useEffect(() => {
    if (data) {
      setProductCode(data.productCode)
      setCookie(
        COOKIE_PRODUCT_REDIRECT_KEY,
        REDIRECT_MAP.get(data.productCode) ?? ''
      )
      setResolution(data.resolution)
      if (data.applicationEmail) {
        setEmail(data.applicationEmail)
        setCookie(COOKIE_EMAIL_KEY, data.applicationEmail)
        setCookie(COOKIE_EMAIL_DISPLAY_KEY, data.applicationEmail)
        identify('', data.applicationEmail)
      }
      /**
       * Set the Registration Flow cookie value in the case that the user is
       * directed to the set password page from the landing page in order to
       * accurately log segment event names
       */
      if (data.resolution === 'SET_PASSWORD') {
        setCookie(COOKIE_REG_FLOW_KEY, RegistrationFlow.NoMatch)
      }
      if (data.resolution === 'NONE') {
        clearRegCookies()
      }
    }
  }, [data])

  useEffect(() => {
    if (error) {
      notifyBugsnag({
        error: error as Error,
        name: 'GetRegistration',
        prefix: 'LandingPage',
      })
    }
  }, [error])

  return (
    <>
      {(isLoading || isAuthCompleteLoading) && <Loading />}
      {shouldShowError && <Navigate to={ERROR_ROUTE} />}
      {data && (
        <>
          {data.resolution === 'LOGIN' && (
            /**
             * If authenticated complete registration call is completed, handle
             * redirects accordingly.
             *
             * If authenticated complete registration call is never needed, redirect
             * to full match flow.
             */
            <>
              {isRegistrationComplete && (
                <>
                  {authCompleteData.registrationComplete === 'true' ? (
                    <ExternalRedirect
                      route={REDIRECT_MAP.get(data.productCode) ?? ''}
                    />
                  ) : (
                    <Navigate to={FULL_MATCH_ROUTE} />
                  )}
                </>
              )}
              {!shouldFetchAuthComplete && <Navigate to={FULL_MATCH_ROUTE} />}
              {shouldRedirectToLogin && <Navigate to={FULL_MATCH_ROUTE} />}
            </>
          )}
          {data.resolution === 'SET_PASSWORD' && (
            <Navigate to={CREATE_PASSWORD_ROUTE} />
          )}
          {data.resolution === 'EMAIL_DECONFLICT_OPTIONAL' && (
            <Navigate to={EMAIL_DECONFLICT_OPTIONAL_ROUTE} />
          )}
          {data.resolution === 'EMAIL_DECONFLICT_REQUIRED' && (
            <Navigate to={EMAIL_DECONFLICT_REQUIRED_ROUTE} />
          )}
          {data.resolution === 'NONE' && (
            <ExternalRedirect
              route={REDIRECT_MAP.get(data.productCode) ?? ''}
            />
          )}
        </>
      )}
    </>
  )
}
