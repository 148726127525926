import ErrorPanel from './ErrorPanel'

interface AuthErrorProps {
  errors: string[] | JSX.Element | string | undefined | unknown
  className?: string
}

export function AuthError({ className = '', errors }: AuthErrorProps) {
  return <ErrorPanel className={`${className} error`} errors={errors} />
}

export function DefaultAuthError() {
  return <AuthError errors={'We encountered an error. Please try again.'} />
}
