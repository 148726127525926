import React, { createContext, useMemo } from 'react'
// import * as braze from '@braze/web-sdk'
// Expect error needed for invalid typings inside @segment packages
import { getEnvValue } from 'utilities'
import { AnalyticsBrowser } from '@segment/analytics-next'

export const AnalyticsContext = createContext<AnalyticsBrowser>(undefined!)
AnalyticsContext.displayName = 'AnalyticsContext'

interface Props {
  children: React.ReactNode
}

export const AnalyticsProvider = ({ children }: Props) => {
  const writeKey = getEnvValue('REACT_APP_SEGMENT_WRITE_KEY')
  const analytics = useMemo(() => {
    // braze.initialize(getEnvValue('REACT_APP_BRAZE_API_KEY'), {
    //   baseUrl: getEnvValue('REACT_APP_BRAZE_SDK_URL'),
    //   allowUserSuppliedJavascript: true,
    // })
    // Expect-error needed for invalid typings inside @segment packages

    return AnalyticsBrowser.load({
      writeKey,
    })
  }, [writeKey])
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}
