import { useEffect, useMemo, useState } from 'react'

type Breakpoint = 'sm' | 'md' | 'lg'

const breakpoints = {
  0: 'sm',
  768: 'md',
  1024: 'lg',
}

const useBreakpoint: () => {
  isPhone: boolean
  isTablet: boolean
  isDesktop: boolean
} = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>('sm')
  const device = useMemo(() => {
    switch (breakpoint) {
      case 'sm':
        return { isPhone: true, isTablet: false, isDesktop: false }
      case 'md':
        return { isPhone: false, isTablet: true, isDesktop: false }
      case 'lg':
        return { isPhone: false, isTablet: false, isDesktop: true }
    }
  }, [breakpoint])
  const [windowSize, setWindowSize] = useState<
    Partial<{ height: number; width: number }>
  >({
    width: undefined,
    height: undefined,
  })
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    if (windowSize.width) {
      if (windowSize.width > 0 && windowSize.width < 768) {
        setBreakpoint(breakpoints[0] as Breakpoint)
      }
      if (windowSize.width > 768 && windowSize.width < 1024) {
        setBreakpoint(breakpoints[768] as Breakpoint)
      }
      if (windowSize.width >= 1024) {
        setBreakpoint(breakpoints[1024] as Breakpoint)
      }
    }
    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize.width])
  return device
}

export default useBreakpoint
